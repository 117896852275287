import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../AdminNavbar/AdminNavbar';
import StatsSection from '../StatsSection/StatsSection';
import MessagesSection from '../MessagesSection/MessagesSection';

import Adminbloglist from '../AdminBlogList/Adminbloglist';
import Adminpackagelist from '../AdminPackageList/Adminpackagelist';
import AdminApproval from '../AdminApproval/AdminApproval';
import AdminUser from '../AdminUser/AdminUser';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [stats, setStats] = useState({ messages: [] });
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin/login');
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <>
            <StatsSection />
            <MessagesSection messages={stats.messages} />
          </>
        );
      case 'users':
        return <AdminUser />;
      case 'packages':
        return <Adminpackagelist />;
      case 'blogs':
        return <Adminbloglist />;
      case 'approvals':
        return <AdminApproval />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar activeTab={activeTab} setActiveTab={setActiveTab} handleLogout={handleLogout} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="bg-white p-6 rounded-lg shadow-md border">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
