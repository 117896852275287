import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/logo.jpg';
// import logo from '../../assets/HimalayanSpiritLogo.jpg';
// import LanguageTranslator from '../MultiLanguage/LanguageTranslator';

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled 
        ? 'bg-white/95 shadow-lg backdrop-blur-md' 
        : 'bg-black/20 backdrop-blur-sm'
    }`}>
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo and Brand Name */}
          <Link to="/" className="flex items-center space-x-3">
            <img 
              src={logo} 
              alt="Mantra Mountain Logo" 
              className="h-14 w-auto rounded-full"
            />
            <span className={`font-semibold text-xl ${
              isScrolled ? 'text-gray-800' : 'text-white'
            }`}>
              Mantra Mountain Nepal Treks
            </span>
          </Link>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-md focus:outline-none"
          >
            <div className="w-6 h-5 relative flex flex-col justify-between">
              <span className={`w-full h-0.5 rounded-full transform transition-all duration-300 ${
                isScrolled ? 'bg-gray-800' : 'bg-white'
              } ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
              <span className={`w-full h-0.5 rounded-full transition-all duration-300 ${
                isScrolled ? 'bg-gray-800' : 'bg-white'
              } ${isMenuOpen ? 'opacity-0' : ''}`}></span>
              <span className={`w-full h-0.5 rounded-full transform transition-all duration-300 ${
                isScrolled ? 'bg-gray-800' : 'bg-white'
              } ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
            </div>
          </button>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-12">
            <Link to="/" className={`nav-link text-lg font-medium ${
              isScrolled ? 'text-gray-800 hover:text-indigo-600' : 'text-white hover:text-white'
            } ${location.pathname === '/' ? 'font-semibold' : ''}`}>
              Home
            </Link>
            <Link to="/Packages" className={`nav-link text-lg font-medium ${
              isScrolled ? 'text-gray-800 hover:text-indigo-600' : 'text-white hover:text-white'
            } ${location.pathname === '/Packages' ? 'font-semibold' : ''}`}>
              Packages
            </Link>
            <Link to="/blog" className={`nav-link text-lg font-medium ${
              isScrolled ? 'text-gray-800 hover:text-indigo-600' : 'text-white hover:text-white'
            } ${location.pathname === '/blog' ? 'font-semibold' : ''}`}>
              Blog
            </Link>
            <Link to="/About" className={`nav-link text-lg font-medium ${
              isScrolled ? 'text-gray-800 hover:text-indigo-600' : 'text-white hover:text-white'
            } ${location.pathname === '/About' ? 'font-semibold' : ''}`}>
              About
            </Link>
            <Link to="/Contact" className={`px-6 py-2.5 rounded-full text-lg font-medium ${
              isScrolled 
                ? 'bg-indigo-600 text-white hover:bg-indigo-700' 
                : 'bg-white/10 text-white hover:bg-white/20 backdrop-blur-sm'
            } transition-all duration-300 shadow-md hover:shadow-lg`}>
              Contact
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden transition-all duration-300 ${
          isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}>
          <div className={`py-4 space-y-4 ${
            isScrolled ? 'bg-white' : 'bg-black/90 backdrop-blur-md'
          }`}>
            <Link to="/" className={`block px-4 py-2 text-lg ${
              isScrolled ? 'text-gray-800 hover:bg-gray-100' : 'text-white hover:bg-white/10'
            } ${location.pathname === '/' ? 'font-semibold' : ''}`}>
              Home
            </Link>
            <Link to="/Packages" className={`block px-4 py-2 text-lg ${
              isScrolled ? 'text-gray-800 hover:bg-gray-100' : 'text-white hover:bg-white/10'
            } ${location.pathname === '/Packages' ? 'font-semibold' : ''}`}>
              Packages
            </Link>
            <Link to="/blog" className={`block px-4 py-2 text-lg ${
              isScrolled ? 'text-gray-800 hover:bg-gray-100' : 'text-white hover:bg-white/10'
            } ${location.pathname === '/blog' ? 'font-semibold' : ''}`}>
              Blog
            </Link>
            <Link to="/About" className={`block px-4 py-2 text-lg ${
              isScrolled ? 'text-gray-800 hover:bg-gray-100' : 'text-white hover:bg-white/10'
            } ${location.pathname === '/About' ? 'font-semibold' : ''}`}>
              About
            </Link>
            <Link to="/Contact" className={`block mx-4 px-4 py-2 text-lg text-center rounded-full ${
              isScrolled 
                ? 'bg-indigo-600 text-white hover:bg-indigo-700' 
                : 'bg-white/10 text-white hover:bg-white/20'
            }`}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
