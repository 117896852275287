import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import {
  MapPin, Globe, Calendar, Map, CheckCircle, XCircle, Star, Users, Award, Shield, Heart, Share2,
  Compass, Mountain, Clock, ChevronDown, ChevronUp,
  User2Icon
} from 'lucide-react';
import { FaSearch, FaFilter } from 'react-icons/fa';
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Footer from '../footer/Footer';
import CampaignSection from '../campaign/CampaignSection';

const PackageDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeDay, setActiveDay] = useState(null);
  const [wishlist, setWishlist] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currency, setCurrency] = useState('USD');

  // Fetch package data
  useEffect(() => {
    const fetchPackage = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`);
        // const response = await axios.get(`http://localhost:5000/api/package/${slug}`);
        console.log(response.data);
        
        setPackageData(response.data);
        setSelectedImage(response.data.overallImages[0]);
      } catch (error) {
        console.error('Error fetching package:', error);
        setError(error.response?.data?.message || 'Failed to load package details');
      } finally {
        setLoading(false);
      }
    };

    if (slug) fetchPackage();
  }, [slug]);

  // Scroll progress
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Countdown timer (3 days from now)
  function calculateTimeLeft() {
    const difference = +new Date().setDate(new Date().getDate() + 3) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }
    return timeLeft;
  }

  useEffect(() => {
    const timer = setInterval(() => setTimeLeft(calculateTimeLeft()), 60000);
    return () => clearInterval(timer);
  }, []);

  // Handlers
  const handleBookNow = () => setSelectedPackage(packageData);
  const handleCloseBooking = () => setSelectedPackage(null);
  const handleBooking = (date, formData) => {
    console.log('Booking:', { packageData, date, formData });
    // Add payment logic here if needed
  };
  const toggleWishlist = () => setWishlist(!wishlist);
  const toggleDay = (day) => setActiveDay(activeDay === day ? null : day);
  const toggleFilters = () => setShowFilters(!showFilters);

  // Currency conversion (mock rates)
  const convertPrice = (price) => {
    const rates = { USD: 1, EUR: 0.92, GBP: 0.78 };
    return Math.round(price * (rates[currency] || 1));
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-blue-100">
        <motion.div
          animate={{ scale: [1, 1.2, 1], rotate: [0, 360] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
          className="relative"
        >
          <Mountain className="w-16 h-16 text-indigo-600" />
          <motion.div
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{ duration: 1.5, repeat: Infinity }}
            className="absolute inset-0 flex items-center justify-center text-indigo-600 font-bold text-lg"
          >
            Trekking...
          </motion.div>
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          {error} <button onClick={() => navigate(-1)} className="ml-4 text-indigo-600 underline">Go Back</button>
        </div>
      </div>
    );
  }

  if (!packageData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-gray-600 text-lg font-semibold bg-gray-100 px-6 py-3 rounded-lg shadow">
          Package Not Found <button onClick={() => navigate(-1)} className="ml-4 text-indigo-600 underline">Go Back</button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 via-blue-50 to-indigo-100 pt-20 pb-12">
      {/* Progress Bar */}
      <div className="fixed top-0 left-0 w-full h-1 bg-gray-200 z-50">
        <div
          className="h-full bg-indigo-600 transition-all duration-300"
          style={{ width: `${scrollProgress}%` }}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="relative mb-12"
        >
          <div className="relative h-[60vh] sm:h-[70vh] rounded-3xl overflow-hidden shadow-2xl">
            <img
              src={selectedImage}
              alt={packageData.title}
              className="w-full h-full object-cover transition-transform duration-700 hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-black/30 to-black/70"></div>
            <div className="mt-52 absolute inset-0 flex flex-col justify-center items-center text-white text-center px-4 sm:px-6">
              <motion.h1
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-4xl sm:text-2xl md:text-6xl font-extrabold mb-4 tracking-tight drop-shadow-lg"
              >
                {packageData.title}
              </motion.h1>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-lg sm:text-xl md:text-2xl max-w-3xl mx-auto mb-2 drop-shadow-md"
              >
                Unleash Your Adventure in the Heart of the Himalayas
              </motion.p>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="flex items-center gap-4 mb-6 text-sm sm:text-base"
              >
                <span className="flex items-center bg-white/20 px-4 py-2 rounded-full shadow-md">
                  <Calendar className="w-5 h-5 mr-2" /> {packageData.duration}
                </span>
                <span className="flex items-center bg-white/20 px-4 py-2 rounded-full shadow-md">
                  <Mountain className="w-5 h-5 mr-2" /> {packageData.difficulty}
                </span>
                <span className="sm:text-lg font-bold drop-shadow-lg">{currency} {convertPrice(packageData.price)}</span>
              </motion.div>
              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
                onClick={handleBookNow}
                className="px-2 py-2 md:mb-6 text-lg bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-full hover:from-indigo-700 hover:to-green-700 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:scale-105"
              >
                Book Now - Only 5 Spots Left!
              </motion.button>
            </div>
            {/* Limited Time Offer Badge */}
            <div className="absolute top-4 left-4 bg-yellow-400 text-white px-4 py-2 rounded-full font-semibold flex items-center gap-2 shadow-md">
              <Clock className="w-5 h-5" /> Limited Offer: {timeLeft.days}d {timeLeft.hours}h Left!
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-8 gap-2 mt-4"
          >
            {packageData.overallImages.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Preview ${index + 1}`}
                className={`w-full h-20 sm:h-24 object-cover rounded-lg cursor-pointer transition-transform duration-300 hover:scale-105 ${selectedImage === img ? 'ring-2 ring-indigo-600' : ''}`}
                onClick={() => setSelectedImage(img)}
              />
            ))}
          </motion.div>
        </motion.section>

        {/* Sticky Booking Bar (Mobile) */}
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed bottom-0 left-0 right-0 z-50 bg-gradient-to-r from-indigo-600 to-green-600 text-white p-4 shadow-lg lg:hidden"
        >
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold">{currency} {convertPrice(packageData.price)}</p>
              <p className="text-sm">Hurry! {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m Left</p>
            </div>
            <button
              onClick={handleBookNow}
              className="px-6 py-2 bg-white text-indigo-600 font-semibold rounded-full hover:bg-gray-100 transition-colors shadow-md"
            >
              Book Now
            </button>
          </div>
        </motion.div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 sm:gap-8">
          {/* Left Column */}
          <div className="lg:col-span-2">
            {/* Overview */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-3xl shadow-xl p-6 sm:p-8 mb-6 sm:mb-8 border border-gray-100"
            >
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 tracking-tight">Trip Overview</h2>
              <p className="text-gray-600 leading-relaxed text-base sm:text-lg">
                {packageData.description || "Embark on an epic adventure through Nepal's pristine wilderness, guided by experts who've trekked these trails for decades."}
              </p>
              <div className="mt-6 grid grid-cols-2 gap-4 sm:gap-6 text-gray-600 text-sm sm:text-base">
                <div className="flex items-center"><Globe className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.country}</div>
                <div className="flex items-center"><MapPin className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.region}</div>
                <div className="flex items-center"><Compass className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.dailyDistance}</div>
                <div className="flex items-center"><Mountain className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.dailyAscent}</div>
              </div>
            </motion.div>

            {/* Itinerary */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="bg-white rounded-3xl shadow-xl p-6 sm:p-8 mb-6 sm:mb-8 border border-gray-100"
            >
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 tracking-tight">Daily Itinerary</h2>
              {packageData.itinerary.map((day, index) => (
                <div key={index} className="mb-6 last:mb-0">
                  <button
                    onClick={() => toggleDay(index + 1)}
                    className="w-full flex justify-between items-center bg-gradient-to-r from-gray-50 to-indigo-50 p-4 rounded-xl hover:bg-gray-100 transition-colors duration-300"
                  >
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-900">Day {day.day}: {day.title}</h3>
                    {activeDay === index + 1 ? <ChevronUp className="w-6 h-6" /> : <ChevronDown className="w-6 h-6" />}
                  </button>
                  {activeDay === index + 1 && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      transition={{ duration: 0.3 }}
                      className="mt-4 pl-4 sm:pl-6 border-l-4 border-indigo-600"
                    >
                      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-4 text-sm sm:text-base text-gray-600">
                        <div className="flex items-center"><Map className="w-4 h-4 mr-2 text-indigo-600" /> {day.distance}</div>
                        <div className="flex items-center"><Clock className="w-4 h-4 mr-2 text-indigo-600" /> {day.duration}</div>
                        <div>↑ {day.ascent}</div>
                        <div>↓ {day.descent}</div>
                      </div>
                      <p className="text-gray-600 mb-4">{day.description}</p>
                      {day.imageUrl && (
                        <img
                          src={day.imageUrl}
                          alt={`Day ${day.day}`}
                          className="w-full h-auto rounded-lg shadow-md"
                        />
                      )}
                    </motion.div>
                  )}
                </div>
              ))}
            </motion.div>

            {/* Testimonials */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="bg-white rounded-3xl shadow-xl p-6 sm:p-8 mb-6 sm:mb-8 border border-gray-100"
            >
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 tracking-tight">What Trekkers Say</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {[
                  { name: "Alex R.", text: "A life-changing trek with jaw-dropping views!", rating: 5 },
                  { name: "Sophie L.", text: "The best guides I've ever had—truly unforgettable!", rating: 5 },
                ].map((review, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-xl shadow-md">
                    <div className="flex items-center mb-4">
                      <div className="w-12 h-12 rounded-full bg-indigo-200 flex items-center justify-center text-white font-bold mr-4">
                        {review.name.charAt(0)}
                      </div>
                      <div>
                        <p className="font-semibold text-gray-800">{review.name}</p>
                        <div className="flex text-yellow-400">
                          {[...Array(review.rating)].map((_, i) => <Star key={i} className="w-4 h-4 fill-current" />)}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600">{review.text}</p>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Right Column (Sidebar) */}
          <aside className="lg:col-span-1">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }}
              className="bg-white rounded-3xl shadow-xl p-6 sticky top-20 border border-gray-100"
            >
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4 tracking-tight">Trip Highlights</h3>
              <div className="grid grid-cols-1 gap-4 mb-6">
                {[
                  { icon: <Users className="w-6 h-6 text-indigo-600" />, text: "Small Groups (Max 12)" },
                  { icon: <Shield className="w-6 h-6 text-indigo-600" />, text: "Certified Expert Guides" },
                  { icon: <Award className="w-6 h-6 text-indigo-600" />, text: "Best Price Guarantee" },
                  { icon: <Star className="w-6 h-6 text-indigo-600" />, text: "4.9/5 from 200+ Reviews" },
                ].map((highlight, index) => (
                  <div key={index} className="flex items-center bg-gradient-to-r from-gray-50 to-indigo-50 p-3 rounded-lg shadow-sm">
                    {highlight.icon}
                    <p className="ml-3 text-gray-600 font-medium">{highlight.text}</p>
                  </div>
                ))}
              </div>

              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4 tracking-tight">Trip Details</h3>
              <div className="grid grid-cols-1 gap-4 mb-6 text-gray-600 text-sm sm:text-base">
                <div className="flex items-center"><User2Icon className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.author.username}</div>
                <div className="flex items-center"><Globe className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.country}</div>
                <div className="flex items-center"><MapPin className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.region}</div>
                <div className="flex items-center"><Compass className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.dailyDistance}</div>
                <div className="flex items-center"><Mountain className="w-5 h-5 text-indigo-600 mr-2" /> {packageData.dailyAscent}</div>
              </div>

              <div className="mt-6">
                <h3 className="flex items-center text-lg font-bold text-gray-900 mb-4">
                  <CheckCircle className="w-5 h-5 mr-2 text-green-500" /> Included
                </h3>
                <ul className="space-y-2 text-gray-600">
                  {packageData.included.map((item, index) => (
                    <li key={index} className="flex items-center"><CheckCircle className="w-4 h-4 mr-2 text-green-500" /> {item}</li>
                  ))}
                </ul>
              </div>

              <div className="mt-6">
                <h3 className="flex items-center text-lg font-bold text-gray-900 mb-4">
                  <XCircle className="w-5 h-5 mr-2 text-red-500" /> Not Included
                </h3>
                <ul className="space-y-2 text-gray-600">
                  {packageData.excluded.map((item, index) => (
                    <li key={index} className="flex items-center"><XCircle className="w-4 h-4 mr-2 text-red-500" /> {item}</li>
                  ))}
                </ul>
              </div>

              <button
                onClick={handleBookNow}
                className="mt-6 w-full px-6 py-3 bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-full hover:from-indigo-700 hover:to-green-700 transition-all duration-300 shadow-md hover:shadow-xl"
              >
                Book This Trek Now
              </button>

              <div className="mt-4 flex justify-between">
                <button
                  onClick={toggleWishlist}
                  className={`flex items-center ${wishlist ? 'text-red-500' : 'text-gray-600'} hover:text-red-600 transition-colors`}
                >
                  <Heart className="w-5 h-5 mr-2" /> {wishlist ? 'Saved' : 'Save'}
                </button>
                <button className="flex items-center text-gray-600 hover:text-indigo-600 transition-colors">
                  <Share2 className="w-5 h-5 mr-2" /> Share
                </button>
              </div>
            </motion.div>

            {/* Static Trek Route Map */}
           
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
              className="bg-white rounded-3xl shadow-xl p-6 mt-6 sm:mt-8 border border-gray-100"
            >
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4 tracking-tight">Trek Route Overview</h3>
              <div className="h-64 sm:h-96 rounded-lg overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930929.1771926308!2d83.88375351603042!3d28.185790822933768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d7c9ad462495687%3A0xad66f41f291153ac!2sMantra%20Mountain%20Nepal%20Treks%20PVT%20LTD!5e1!3m2!1sen!2snp!4v1737437235029!5m2!1sen!2snp"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="w-full h-full"
                />
              </div>
            </motion.div>
          </aside>
        </div>
        

        {/* Related Packages
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1.2 }}
          className="mt-12 sm:mt-16"
        >
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 tracking-tight">Explore Similar Adventures</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {[
              { title: "Everest Base Camp Trek", price: 1499, slug: "everest-base-camp-trek", img: "https://via.placeholder.com/300x200?text=Everest" },
              { title: "Annapurna Circuit", price: 1299, slug: "annapurna-circuit", img: "https://via.placeholder.com/300x200?text=Annapurna" },
            ].map((pkg, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-white rounded-2xl shadow-lg p-4 hover:shadow-xl transition-all duration-300 cursor-pointer"
                onClick={() => handlePackageClick(pkg.slug)}
              >
                <img
                  src={pkg.img}
                  alt={pkg.title}
                  className="w-full h-40 object-cover rounded-lg mb-4"
                />
                <h3 className="text-lg font-semibold text-gray-800">{pkg.title}</h3>
                <p className="text-indigo-600 font-bold">{currency} {convertPrice(pkg.price)}</p>
                <button className="mt-2 text-indigo-600 hover:underline text-sm">Learn More</button>
              </motion.div>
            ))}
          </div>
        </motion.div> */}

        <CampaignSection />
      </div>

      {/* // Pass currency to BookingCalendar */}
{selectedPackage && (
  <BookingCalendar
    packageData={selectedPackage}
    onClose={handleCloseBooking}
    onBook={handleBooking}
    currency={currency} // Pass currency
  />
)}

      <Footer />
    </div>
  );
};

export default PackageDetail;