import React, { useState, useEffect } from 'react';
import { Star, ExternalLink } from 'lucide-react';
import GiveReview from './GiveReview';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Replace with your actual database endpoint
        const response = await fetch('https://api.apify.com/v2/datasets/yNtVxIhW7MJhAlx1B/items?token=apify_api_iImp4r20pIA3QFDA2EFQy1xs43PG8k0mi9Tv', {

        // const response = await fetch('http://localhost:5000/api/reviews', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setReviews(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        size={20}
        className={`${index < rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'} transition-all duration-300`}
      />
    ));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <section className="py-2 bg-gradient-to-b from-gray-50 to-white" itemScope itemType="https://schema.org/Review">
      <div className="container mx-auto px-4">
        {/* SEO-optimized header */}
        <header className="text-center mb-2">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4" itemProp="name">
            Customer Reviews for Nepal Trekking
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto" itemProp="description">
            Discover authentic experiences from our trekkers in Nepal - real stories, genuine feedback
          </p>
        </header>

        {/* Marquee-style floating reviews */}
        <div className="overflow-hidden relative">
          <div className="flex animate-marquee gap-6 pb-8">
            {reviews.concat(reviews).map((review, index) => ( // Duplicated for seamless animation
              <article
                key={`${review.id}-${index}`}
                className="flex-shrink-0 w-[320px] bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all duration-300 border border-gray-100"
                itemProp="review"
                itemScope
                itemType="https://schema.org/Review"
              >
                <div className="flex items-center mb-4">
                  <img
                    src={review.reviewerPhotoUrl || 'https://via.placeholder.com/50'}
                    alt={`${review.name}'s profile`}
                    className="w-12 h-12 rounded-full object-cover mr-3"
                    loading="lazy"
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900" itemProp="author">
                      {review.name}
                    </h3>
                    <div className="flex mt-1" itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
                      {renderStars(review.rating || review.stars)}
                      <meta itemProp="ratingValue" content={review.rating || review.stars} />
                      <meta itemProp="bestRating" content="5" />
                    </div>
                  </div>
                </div>
                
                <p className="text-gray-600 text-sm mb-4 line-clamp-3" itemProp="reviewBody">
                  {review.content || review.text}
                </p>

                <a
                  href={review.googleReviewUrl || 'https://www.google.com/maps/place/Mantra+Mountain+Nepal+Treks+PVT+LTD/@28.397455,84.1301506,746866m/data=!3m1!1e3!4m8!3m7!1s0x2d7c9ad462495687:0xad66f41f291153ac!8m2!3d28.397455!4d84.1301506!9m1!1b1!16s%2Fg%2F11wm_5m4j1?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D'} // Replace with actual Google Review URL
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors duration-200"
                  itemProp="url"
                >
                  Read Full Review
                  <ExternalLink size={16} className="ml-1" />
                </a>
              </article>
            ))}
          </div>
        </div>

        {/* Call to action */}
        <div className="flex gap-2">
          <div className='sm:w-1/2' >
          <a
            href="https://www.google.com/maps/place/Mantra+Mountain+Nepal+Treks+PVT+LTD/@28.397455,84.1301506,746866m/data=!3m1!1e3!4m8!3m7!1s0x2d7c9ad462495687:0xad66f41f291153ac!8m2!3d28.397455!4d84.1301506!9m1!1b1!16s%2Fg%2F11wm_5m4j1?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D" // Replace with your Google Reviews page
            target="_blank"
            rel="noopener noreferrer"
            className="items-center py-2 px-3 sm-px-0 sm:py-0 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 text-lg sm:text-sm w-full font-semibold"
          > 
            See All Reviews
          </a>
          </div>
          <div className='sm:w-1/2 ' >
          <GiveReview/>
         </div>
        </div>
      </div>

      {/* Tailwind CSS Animation */}
      <style jsx>{`
        @keyframes marquee {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); }
        }
        .animate-marquee {
          animation: marquee 30s linear infinite;
          will-change: transform;
        }
        .animate-marquee:hover {
          animation-play-state: paused;
        }
      `}</style>
    </section>
  );
};

export default Reviews;

// import React, { useState, useEffect } from 'react';
// import { Star } from 'lucide-react';
// import './review.css';
// // import GiveReview from './GiveReview';

// const Reviews = () => {
//   const [reviews, setReviews] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await fetch('https://api.apify.com/v2/datasets/yNtVxIhW7MJhAlx1B/items?token=apify_api_iImp4r20pIA3QFDA2EFQy1xs43PG8k0mi9Tv');
//         const data = await response.json();
//         setReviews(data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error('Error fetching reviews:', error);
//         setIsLoading(false);
//       }
//     };

//     fetchReviews();
//   }, []);
//   const renderStars = (rating) => {
//     return [...Array(5)].map((_, index) => (
//       <Star 
//         key={index} 
//         color={index < rating ? 'gold' : 'gray'} 
//         fill={index < rating ? 'gold' : 'none'}
//       />
//     ));
//   };

//   if (isLoading) return <div>Loading...</div>;

//   return (
//     <div className="testimonial-container">
//       <div className="testimonial-header">
//         <h2 className="testimonial-title">Testimonials</h2>
//         <p className="testimonial-subtitle">
//           Hear what our customers have to say about their experiences
//         </p>
//       </div>

//       <div className="testimonial-grid">
//         {reviews.slice(0, 3).map((review, index) => (
//           <div key={index} className="testimonial-card">
//             <img 
//               src={review.reviewerPhotoUrl} 
//               alt={review.name}
//               className="testimonial-avatar"
//             />
//             <h3 className="testimonial-name">{review.name}</h3>
//             {/* <h4 className="testimonial-role">{review.categoryName}</h4> */}
//             <p className="testimonial-text">
//               {review.text.slice(0, 150)}...
//             </p>
//             <div className="testimonial-stars">
//               {renderStars(review.stars)}
//             </div>
//             <a 
//               href={review.reviewUrl} 
//               target="_blank" 
//               rel="noopener noreferrer"
//               className="testimonial-link"
//             >
//               Read Full Review
//             </a>
            
//           </div>
//         ))}
//       </div>
//       {/* <GiveReview/> */}
//     </div>
//   );
// };

// export default Reviews;