// LazyImage.jsx
import React from 'react';

const LazyImage = ({ src, alt, className, ...props }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      loading="lazy" // Native browser lazy-loading
      decoding="async" // Asynchronous decoding for better performance
      fetchpriority={src.includes('overallImages') ? 'high' : 'auto'} // Prioritize key images
      {...props}
    />
  );
};

export default LazyImage;