import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
// import Package from "./pages/Package";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import BlogList from './Components/BlogPost/BlogList.js';
import CreateBlog from './Components/admin/AdminBlogList/CreateBlog.js';
import BlogPost from './Components/BlogPost/BlogPost.jsx';
import PackageUpload from './Components/admin/AdminPackageList/PackageUpload.jsx';
import PackageDetail from "./Components/PackageDetail/PackageDetail.jsx";
import PackageList from '../src/Components/PackageList/PackageList';
// import EditPackage from "./Components/EditPackage/EditPackage";
import AdminDashboard from "./Components/admin/AdminDashboard/AdminDashboard.jsx";
import AdminLogin from "./Components/admin/AdminDashboard/AdminLogin.jsx";
import AdminLayout from "./Components/admin/AdminDashboard/AdminLayout";
import UpdateBlog from "./Components/admin/AdminBlogList/UpdateBlog.jsx";
import UpdatePackage from "./Components/admin/AdminPackageList/UpdatePackage.jsx";
// import FloatingTranslator from './Components/MultiLanguage/FloatingTranslator';
import Chatbot from './Components/ChatBot/ChatBot';
import GoogleTranslate from "./Components/GoogleTranslate.jsx";

const App = () => {
  const location = useLocation();

  // Check if the current route is an admin route
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <div>
      {/* Conditionally render the regular navbar */}
      {!isAdminRoute && <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/CreateBlog" element={<CreateBlog />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/PackageUpload" element={<PackageUpload />} />
        <Route path="/packages/:slug" element={<PackageDetail />} />
        <Route path="/packages" element={<PackageList />} />

        {/* Admin Routes */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/*" element={<AdminLayout />}>
          <Route index element={<AdminDashboard />} />
        </Route>
        <Route path="/update-blog/:id" element={<UpdateBlog />} />
        <Route path="/update-package/:slug" element={<UpdatePackage />} />
      </Routes>

      {/* Conditionally render the chatbot */}
      {!isAdminRoute && <Chatbot />}
    </div>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App>
    <GoogleTranslate />
    </App>
  </BrowserRouter>
);

export default AppWrapper;
