import React, { useState, useEffect } from 'react';
import { FaGlobe, FaTimes } from 'react-icons/fa';

const FloatingGoogleTranslate = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Add Google Translate script when component mounts or when isOpen becomes true
    const addScript = () => {
      if (!document.querySelector('script[src*="translate.google.com"]')) {
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);
      }
    };

    // Initialize Google Translate
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'google_translate_element'
      );
    };

    // Only load the script when the translate element is visible
    if (isOpen) {
      addScript();
    }

    // Cleanup function
    return () => {
      // We don't remove the script on unmount as it could be used elsewhere
    };
  }, [isOpen]);

  return (
    <div className="fixed bottom-24 right-4 z-50 flex flex-col items-end">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="w-12 h-12 rounded-full bg-blue-600 text-white shadow-lg flex items-center justify-center hover:bg-blue-700 transition-all duration-300 border border-blue-500"
        aria-label={isOpen ? 'Close translator' : 'Open translator'}
      >
        {isOpen ? <FaTimes className="w-5 h-5" /> : <FaGlobe className="w-6 h-6" />}
      </button>
      
      {isOpen && (
        <div className="mt-4 bg-white rounded-lg shadow-xl border border-gray-200 p-4 w-64">
          <h3 className="text-lg font-semibold text-gray-800 mb-3">Translate this page:</h3>
          <div id="google_translate_element"></div>
        </div>
      )}
    </div>
  );
};

export default FloatingGoogleTranslate;