import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { motion } from 'framer-motion';
import { FaClock, FaUser, FaCalendar, FaFacebook, FaTwitter, FaLinkedin, FaCopy, FaCheck } from 'react-icons/fa';
import Footer from "../footer/Footer.jsx";

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeHeading, setActiveHeading] = useState('');
  const [copied, setCopied] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const blogContentRef = useRef(null);

  // Fetch blog data
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/slug/${slug}`);
        setBlog(response.data);

        // Extract headings for TOC
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = response.data.content;
        const headingElements = tempDiv.querySelectorAll('h2');
        const tocItems = Array.from(headingElements).map(heading => ({
          id: heading.id || heading.textContent.toLowerCase().replace(/[^a-z0-9]+/g, "-"),
          text: heading.textContent,
        }));
        setHeadings(tocItems);
      } catch (error) {
        setError(error.response?.data?.message || 'Failed to fetch blog');
      } finally {
        setLoading(false);
      }
    };

    if (slug) fetchBlog();
  }, [slug]);

  // Scrollspy and progress bar
  useEffect(() => {
    const handleScroll = () => {
      const headingElements = document.querySelectorAll('h2[id]');
      headingElements.forEach(heading => {
        const rect = heading.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          setActiveHeading(heading.id);
        }
      });

      // Calculate scroll progress
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Copy URL to clipboard
  const copyToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  // Smooth scroll to section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-200">
        <div className="w-12 h-12 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          {error}
        </div>
      </div>
    );
  }

  if (!blog) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 font-sans"
    >
      {/* Progress Bar */}
      <div className="fixed top-0 left-0 w-full h-1 bg-gray-200 z-50">
        <div
          className="h-full bg-indigo-600 transition-all duration-300"
          style={{ width: `${scrollProgress}%` }}
        />
      </div>

      {/* Hero Section */}
      <div className="relative h-[60vh] md:h-[70vh] overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={blog.images[0] || blog.image}
            alt={blog.title}
            className="w-full h-full object-cover transition-transform duration-700 ease-in-out hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/60"></div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center px-4">
          <div className="text-center max-w-3xl mx-auto space-y-4">
            <motion.span
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-block px-4 py-1 text-sm font-medium text-indigo-100 bg-indigo-600/30 rounded-full backdrop-blur-sm"
            >
              Trekking Insights
            </motion.span>
            <motion.h1
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-3xl sm:text-4xl md:text-5xl font-bold text-white leading-tight tracking-tight"
            >
              {blog.title}
            </motion.h1>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="flex flex-wrap justify-center gap-4 text-gray-200 text-sm md:text-base"
            >
              <div className="flex items-center bg-black/40 rounded-full px-3 py-1 backdrop-blur-sm">
                <FaUser className="mr-2 text-indigo-400" />
                <span>By {blog.author?.username || "TrekNepal Team"}</span>
              </div>
              <div className="flex items-center bg-black/40 rounded-full px-3 py-1 backdrop-blur-sm">
                <FaCalendar className="mr-2 text-indigo-400" />
                <span>{new Date(blog.createdAt).toLocaleDateString()}</span>
              </div>
              <div className="flex items-center bg-black/40 rounded-full px-3 py-1 backdrop-blur-sm">
                <FaClock className="mr-2 text-indigo-400" />
                <span>{Math.ceil(blog.content.split(" ").length / 200)} min read</span>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Blog Content */}
          <div className="lg:col-span-8 lg:col-start-2" ref={blogContentRef}>
            <article className="prose prose-lg prose-indigo max-w-none text-gray-800">
              <ReactMarkdown
                children={blog.content}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  h1: ({ node, ...props }) => (
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mt-8 mb-4" {...props} />
                  ),
                  h2: ({ node, ...props }) => (
                    <h2
                      id={props.id || props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, "-")}
                      className="text-2xl md:text-3xl font-semibold text-gray-900 mt-10 mb-4 border-b border-gray-200 pb-2 scroll-mt-24"
                      {...props}
                    />
                  ),
                  h3: ({ node, ...props }) => (
                    <h3 className="text-xl md:text-2xl font-medium text-gray-800 mt-8 mb-3 tracking-tight" {...props} />
                  ),
                  h4: ({ node, ...props }) => (
                    <h4 className="text-lg md:text-xl font-medium text-gray-700 mt-6 mb-2" {...props} />
                  ),
                  p: ({ node, ...props }) => (
                    <p className={`text-gray-700 leading-relaxed mb-4 ${props.className || ''}`} {...props} />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul className="list-disc list-outside pl-5 mb-4 text-gray-700" {...props} />
                  ),
                  li: ({ node, ...props }) => (
                    <li className="mb-2" {...props} />
                  ),
                  strong: ({ node, ...props }) => (
                    <strong className="font-semibold text-gray-900" {...props} />
                  ),
                  a: ({ node, ...props }) => (
                    <a id={props.id} className="text-indigo-600 hover:underline" {...props} />
                  ),
                  img: ({ node, ...props }) => (
                    <div className="my-6">
                      <img
                        src={props.src}
                        alt={props.alt || "Blog image"}
                        className="w-full rounded-lg shadow-md object-cover"
                      />
                      {props.alt && (
                        <p className="text-sm text-gray-500 mt-2 italic text-center">{props.alt}</p>
                      )}
                    </div>
                  ),
                }}
              />
            </article>

            {/* Share Section with Copy to Clipboard */}
            <div className="mt-8 p-6 bg-white rounded-xl shadow-sm border border-gray-200">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                <h3 className="text-lg font-semibold text-gray-900">Share this article</h3>
                <div className="flex gap-3">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-indigo-50 hover:bg-indigo-100 rounded-full transition-all duration-300 hover:shadow-md"
                  >
                    <FaFacebook className="text-indigo-600 text-lg" />
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(blog.title)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-indigo-50 hover:bg-indigo-100 rounded-full transition-all duration-300 hover:shadow-md"
                  >
                    <FaTwitter className="text-indigo-600 text-lg" />
                  </a>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(blog.title)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-indigo-50 hover:bg-indigo-100 rounded-full transition-all duration-300 hover:shadow-md"
                  >
                    <FaLinkedin className="text-indigo-600 text-lg" />
                  </a>
                  <button
                    onClick={copyToClipboard}
                    className="p-2 bg-indigo-50 hover:bg-indigo-100 rounded-full transition-all duration-300 hover:shadow-md relative group"
                  >
                    {copied ? (
                      <FaCheck className="text-green-500 text-lg" />
                    ) : (
                      <FaCopy className="text-indigo-600 text-lg" />
                    )}
                    <span className="absolute hidden group-hover:block -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs px-2 py-1 rounded">
                      {copied ? "Copied!" : "Copy Link"}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            {/* Author Bio */}
            <div className="mt-6 p-6 bg-white rounded-xl shadow-sm border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">About the Author</h3>
              <div className="flex items-center gap-4">
                <div className="w-12 h-12 rounded-full bg-indigo-200 flex items-center justify-center text-indigo-600 font-bold">
                  {blog.author?.username?.charAt(0).toUpperCase() || "T"}
                </div>
                <div>
                  <p className="text-gray-800 font-medium">{blog.author?.username || "TrekNepal Team"}</p>
                  <p className="text-gray-600 text-sm">Passionate about trekking and sharing Nepal’s wonders with the world. Explore our expert guides and book your adventure today!</p>
                </div>
              </div>
            </div>

            {/* Related Treks Section */}
            <div className="mt-6 p-6 bg-white rounded-xl shadow-sm border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Explore Related Treks</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="p-4 bg-gray-50 rounded-lg hover:shadow-md transition-all duration-300">
                  <h4 className="text-md font-medium text-gray-800">Everest Base Camp Trek</h4>
                  <p className="text-sm text-gray-600">14 days of adventure in the Himalayas.</p>
                  <button
                    onClick={() => navigate('/packages/everest-base-camp-trek')}
                    className="mt-2 inline-block px-4 py-2 bg-indigo-600 text-white text-sm rounded-full hover:bg-indigo-700 transition-colors"
                  >
                    Book Now
                  </button>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg hover:shadow-md transition-all duration-300">
                  <h4 className="text-md font-medium text-gray-800">Annapurna Circuit</h4>
                  <p className="text-sm text-gray-600">A classic multi-day trek with stunning views.</p>
                  <button
                    onClick={() => navigate('/packages/annapurna-base-camp-trek-classic')}
                    className="mt-2 inline-block px-4 py-2 bg-indigo-600 text-white text-sm rounded-full hover:bg-indigo-700 transition-colors"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>

            {/* CTA Button */}
            <div className="mt-6 text-center">
              <button
                onClick={() => navigate('/Packages')}
                className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-full shadow-md hover:bg-indigo-700 transition-all duration-300"
              >
                Book a Trek Now
              </button>
            </div>
          </div>

          {/* Floating Table of Contents */}
          {headings.length > 0 && (
            <div className="lg:col-span-3 lg:col-start-10">
              <div className="sticky top-20">
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-5">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                    <span className="w-6 h-1 bg-indigo-600 rounded-full mr-2"></span>
                    Table of Contents
                  </h3>
                  <nav className="space-y-2 max-h-[60vh] overflow-y-auto">
                    {headings.map((heading, index) => (
                      <button
                        key={index}
                        onClick={() => scrollToSection(heading.id)}
                        className={`block w-full text-left py-2 px-3 rounded-lg text-sm transition-all duration-200 ${
                          activeHeading === heading.id
                            ? 'bg-indigo-50 text-indigo-600 font-medium'
                            : 'text-gray-600 hover:bg-gray-100'
                        }`}
                      >
                        {heading.text}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </motion.div>
  );
};

export default BlogPost;