import React, { useState } from 'react';
import { Star, Send, X } from 'lucide-react';

const GiveReview = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [rating, setRating] = useState(5);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [emailError, setEmailError] = useState('');

  // Email validation regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setIsSubmitting(true);
    setEmailError('');

    try {
      const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/reviews', {
      // const response = await fetch('http://localhost:5000/api/reviews', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, content, rating }),
      });

      if (response.ok) {
        setEmail('');
        setContent('');
        setRating(5);
        setIsDialogOpen(false);
        onSuccess?.();
        alert('Thanks for your review! It will be visible once approved.');
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      alert('Failed to submit review. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStars = (currentRating) => {
    return [...Array(5)].map((_, index) => (
      <button
        key={index}
        type="button"
        onClick={() => setRating(index + 1)}
        className="focus:outline-none"
      >
        <Star
          size={24}
          className={`${
            index < currentRating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'
          } cursor-pointer hover:scale-110 transition-all duration-200`}
        />
      </button>
    ));
  };

  return (
    <>
      {/* Floating Review Button */}
      <div className='sm:w-1/2 '>
      <button
        onClick={() => setIsDialogOpen(true)}
        className=" bg-blue-600 py-2 px-3 text-white rounded-lg shadow-lg hover:bg-blue-700 transition-all duration-300 flex items-center gap-2"
      >
        <Send size={20} />
        <span className="text-sm font-medium">Write a Review</span>
      </button>
      </div>

      {/* Dialog */}
      {isDialogOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-white rounded-xl shadow-2xl p-6 w-full max-w-md mx-4 transform transition-all duration-300 scale-100">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-gray-900">Share Your Experience</h3>
              <button
                onClick={() => setIsDialogOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
              >
                <X size={24} />
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              {/* Star Rating */}
              <div className="flex gap-2 mb-4 justify-center">
                {renderStars(rating)}
              </div>

              {/* Email Input */}
              <div className="mb-4">
                <input
                  type="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`w-full p-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 ${
                    emailError ? 'border-red-500' : 'border-gray-300'
                  }`}
                  required
                />
                {emailError && (
                  <p className="text-red-500 text-xs mt-1">{emailError}</p>
                )}
              </div>

              {/* Review Content */}
              <div className="mb-6">
                <textarea
                  placeholder="Write your review..."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 resize-none h-32"
                  required
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-all duration-200 disabled:opacity-50 flex items-center justify-center gap-2"
              >
                {isSubmitting ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                ) : (
                  <>
                    <Send size={20} />
                    <span>Submit Review</span>
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default GiveReview;