import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaGlobe, FaMapMarkerAlt, FaWalking, FaEdit, FaTrash, FaChevronDown, FaChevronUp, FaSearch, FaFilter, FaPlus } from 'react-icons/fa';
import { LiaCalendarDaySolid } from 'react-icons/lia';
import { Star, X } from 'lucide-react';
import PackageUpload from './PackageUpload';

const AdminPackageList = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [visiblePackages, setVisiblePackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsPerLoad] = useState(6);
  const [currentCount, setCurrentCount] = useState(itemsPerLoad);
  const [searchQuery, setSearchQuery] = useState('');
  const [duration, setDuration] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [region, setRegion] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [actionType, setActionType] = useState(null); // 'update' or 'delete'
  const [showUploadModal, setShowUploadModal] = useState(false);

  // Fetch packages
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('adminToken');
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (Array.isArray(response.data)) {
          setPackages(response.data);
          setFilteredPackages(response.data);
          setVisiblePackages(response.data.slice(0, itemsPerLoad));
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
        setError(error.response?.data?.message || 'Failed to load packages');
      } finally {
        setLoading(false);
      }
    };
    fetchPackages();
  }, []);

  // Filter packages
  const handleFilterApply = () => {
    const filtered = packages.filter((pkg) => {
      const matchesSearch = pkg.title.toLowerCase().includes(searchQuery.toLowerCase() || '');
      const matchesDuration = !duration || pkg.duration === duration;
      const matchesDifficulty = !difficulty || pkg.difficulty === difficulty;
      const matchesRegion = !region || pkg.region.toLowerCase().includes(region.toLowerCase());
      return matchesSearch && matchesDuration && matchesDifficulty && matchesRegion;
    });
    setFilteredPackages(filtered);
    setVisiblePackages(filtered.slice(0, Math.min(itemsPerLoad, filtered.length)));
    setCurrentCount(Math.min(itemsPerLoad, filtered.length));
  };

  useEffect(() => {
    handleFilterApply();
  }, [searchQuery, duration, difficulty, region]);

  // Load more packages
  const loadMore = () => {
    const newCount = currentCount + itemsPerLoad;
    setVisiblePackages(filteredPackages.slice(0, newCount));
    setCurrentCount(newCount);
  };

  // Action handlers
  const handleUpdate = (pkg) => {
    setSelectedPackage(pkg);
    setActionType('update');
  };

  const handleDelete = (pkg) => {
    setSelectedPackage(pkg);
    setActionType('delete');
  };

  const confirmAction = async () => {
    if (!selectedPackage) return;
    const token = localStorage.getItem('adminToken');
    setLoading(true);

    try {
      if (actionType === 'update') {
        navigate(`/update-package/${selectedPackage.slug}`);
      } else if (actionType === 'delete') {
        await axios.delete(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${selectedPackage._id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPackages(packages.filter((pkg) => pkg._id !== selectedPackage._id));
        setFilteredPackages(filteredPackages.filter((pkg) => pkg._id !== selectedPackage._id));
        setVisiblePackages(visiblePackages.filter((pkg) => pkg._id !== selectedPackage._id));
        alert('Package deleted successfully!');
      }
    } catch (err) {
      console.error(`Error ${actionType}ing package:`, err);
      setError(err.response?.data?.message || `Failed to ${actionType} package`);
    } finally {
      setLoading(false);
      setSelectedPackage(null);
      setActionType(null);
    }
  };

  const closeModal = () => {
    setSelectedPackage(null);
    setActionType(null);
  };

  const openUploadModal = () => setShowUploadModal(true);
  const closeUploadModal = () => setShowUploadModal(false);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-100 to-blue-100 pt-20 pb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white shadow-md rounded-xl p-6 mb-8 animate-pulse">
            <div className="h-10 bg-gray-200 rounded w-1/3 mb-4"></div>
            <div className="flex gap-4">
              <div className="h-12 bg-gray-200 rounded w-2/3"></div>
              <div className="h-12 bg-gray-200 rounded w-1/4"></div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white rounded-2xl shadow-lg animate-pulse">
                <div className="h-60 bg-gray-200 rounded-t-2xl"></div>
                <div className="p-4 space-y-4">
                  <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                  </div>
                  <div className="h-10 bg-gray-200 rounded"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-100 to-blue-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 py-12 px-4 sm:px-6 lg:px-8 mt-20">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-center"
      >
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 tracking-tight">
          <span className="bg-gradient-to-r from-green-400 via-blue-500 to-indigo-600 bg-clip-text text-transparent">
            Admin Package Dashboard
          </span>
        </h1>
        <p className="text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto">
          Manage your trekking packages—upload, update, or delete with ease.
        </p>
        <button
          onClick={openUploadModal}
          className="mt-4 px-6 py-3 bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-full hover:from-indigo-700 hover:to-green-700 transition-all duration-300 shadow-md"
        >
          <FaPlus className="inline mr-2" /> Upload Package
        </button>
      </motion.div>

      <div className="max-w-7xl mx-auto">
        {/* Filters */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-transparent rounded-lg sm:p-1 mb-8"
        >
          <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
            <div className="relative w-full sm:w-1/2">
              <input
                type="text"
                placeholder="Search packages..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white shadow-sm text-gray-700 placeholder-gray-400 transition-all"
              />
              <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-600 to-green-600 text-white rounded-full hover:from-indigo-700 hover:to-green-700 transition-colors shadow-md"
            >
              <FaFilter className="w-4 h-4" /> Filters {showFilters ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>

          {showFilters && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.3 }}
              className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Duration</label>
                <select
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="">All</option>
                  <option value="3 Days">3 days</option>
                  <option value="10 Days">10 days</option>
                  <option value="12 Days">12 days</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Difficulty</label>
                <select
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="">All</option>
                  <option value="Easy">Easy</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Hard">Hard</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Region</label>
                <select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="">All</option>
                  <option value="Everest">Everest</option>
                  <option value="Annapurna">Annapurna</option>
                  <option value="Langtang">Langtang</option>
                </select>
              </div>
            </motion.div>
          )}
        </motion.div>

        {/* Package Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {visiblePackages.map((pkg, index) => (
            <motion.div
              key={pkg._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 hover:shadow-xl hover:scale-105"
            >
              <div className="relative h-56 sm:h-60 overflow-hidden">
                <img
                  src={pkg.overallImages[0]}
                  alt={pkg.title}
                  className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                <div className="absolute top-2 left-2 flex items-center bg-white/90 px-2 py-1 rounded-full text-sm font-semibold text-indigo-600 shadow-md">
                  <Star className="w-4 h-4 text-yellow-400 mr-1" /> 4.9
                </div>
                <div className="absolute bottom-4 left-4 text-white">
                  <p className="text-sm font-medium">Price</p>
                  <p className="text-xl sm:text-2xl font-bold">USD {pkg.price}</p>
                </div>
              </div>
              <div className="p-4 sm:p-5">
                <h2 className="text-lg sm:text-xl font-bold text-gray-800 mb-3 line-clamp-2">
                  {pkg.title}
                </h2>
                <div className="grid grid-cols-2 gap-3 text-gray-600 text-sm sm:text-base">
                  <div className="flex items-center"><FaGlobe className="w-4 h-4 text-indigo-600 mr-2" /> {pkg.country}</div>
                  <div className="flex items-center"><FaMapMarkerAlt className="w-4 h-4 text-indigo-600 mr-2" /> {pkg.region}</div>
                  <div className="flex items-center"><LiaCalendarDaySolid className="w-5 h-5 text-indigo-600 mr-2" /> {pkg.duration}</div>
                  <div className="flex items-center"><FaWalking className="w-4 h-4 text-indigo-600 mr-2" /> {pkg.dailyDistance}</div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <button
                    onClick={(e) => { e.stopPropagation(); handleUpdate(pkg); }}
                    className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors"
                  >
                    <FaEdit className="w-4 h-4 mr-1" /> Update
                  </button>
                  <button
                    onClick={(e) => { e.stopPropagation(); handleDelete(pkg); }}
                    className="flex items-center text-red-600 hover:text-red-800 transition-colors"
                  >
                    <FaTrash className="w-4 h-4 mr-1" /> Delete
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
          {visiblePackages.length === 0 && (
            <div className="col-span-full text-center text-gray-600 text-lg sm:text-xl py-12">
              No packages found matching your criteria. Try adjusting your filters!
            </div>
          )}
        </div>

        {/* Load More */}
        {visiblePackages.length < filteredPackages.length && (
          <div className="text-center mt-10 sm:mt-12">
            <button
              onClick={loadMore}
              className="px-6 py-3 text-sm bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-lg shadow-md hover:from-indigo-700 hover:to-green-700 transition-all duration-300"
            >
              Load More Packages
            </button>
          </div>
        )}

        {/* Confirmation Modal */}
        {selectedPackage && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="bg-white rounded-2xl p-6 max-w-lg w-full mx-4 shadow-2xl"
            >
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                {actionType === 'update' ? 'Update Package' : 'Delete Package'}
              </h2>
              <div className="space-y-4">
                <div className="flex items-center">
                  <img
                    src={selectedPackage.overallImages[0]}
                    alt={selectedPackage.title}
                    className="w-32 h-32 object-cover rounded-lg mr-4"
                  />
                  <div>
                    <h3 className="text-xl font-semibold text-gray-800">{selectedPackage.title}</h3>
                    <p className="text-gray-600">USD {selectedPackage.price}</p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3 text-gray-600 text-sm">
                  <p><FaGlobe className="inline w-4 h-4 text-indigo-600 mr-2" /> {selectedPackage.country}</p>
                  <p><FaMapMarkerAlt className="inline w-4 h-4 text-indigo-600 mr-2" /> {selectedPackage.region}</p>
                  <p><LiaCalendarDaySolid className="inline w-5 h-5 text-indigo-600 mr-2" /> {selectedPackage.duration}</p>
                  <p><FaWalking className="inline w-4 h-4 text-indigo-600 mr-2" /> {selectedPackage.dailyDistance}</p>
                </div>
                <p className="text-gray-600">{selectedPackage.description.substring(0, 150)}...</p>
              </div>
              <div className="mt-6 flex justify-between">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 rounded-full border border-gray-300 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmAction}
                  className={`px-4 py-2 text-white rounded-full transition-colors ${
                    actionType === 'update'
                      ? 'bg-indigo-600 hover:bg-indigo-700'
                      : 'bg-red-600 hover:bg-red-700'
                  }`}
                >
                  {actionType === 'update' ? 'Proceed to Update' : 'Confirm Delete'}
                </button>
              </div>
            </motion.div>
          </div>
        )}

        {/* Upload Package Modal */}
        {showUploadModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="bg-white rounded-2xl p-6 max-w-4xl w-full mx-4 shadow-2xl overflow-y-auto max-h-[90vh] relative"
            >
              <button
                onClick={closeUploadModal}
                className="absolute top-4 right-4 p-2 bg-white rounded-full text-gray-600 hover:text-gray-800 transition-colors shadow-md z-10"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold text-gray-900 mb-6 tracking-tight">
                Upload New Package
              </h2>
              <PackageUpload />
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPackageList;