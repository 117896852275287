import React from 'react';

const StatCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500 mb-1">{title}</p>
        <p className="text-2xl font-semibold text-gray-800">{value}</p>
      </div>
      <div className="p-3 bg-indigo-50 rounded-full">
        <Icon className="text-indigo-600" size={24} />
      </div>
    </div>
    <div className="mt-4">
      <div className="h-1 bg-gray-200 rounded-full">
        <div 
          className="h-1 bg-indigo-600 rounded-full" 
          style={{ width: `${Math.min(100, value)}%` }}
        />
      </div>
    </div>
  </div>
);

export default StatCard; 