import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CalendarRange, Filter } from 'lucide-react';
import axios from 'axios';

const BookingsChart = () => {
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState('');
  const [filterApplied, setFilterApplied] = useState(false);
  
  const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];
  
  const years = [2023, 2024, 2025].map(year => ({ 
    value: year.toString(), 
    label: year.toString() 
  }));

  const fetchBookings = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('adminToken');
      if (!token) {
        setError('Authentication required');
        setLoading(false);
        return;
      }

      let url = 'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/filter-booking?';
      
      if (year) url += `year=${year}`;
      if (month) url += `${year ? '&' : ''}month=${month}`;
      
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Process the data for chart display
      const bookings = response.data.data || [];
      const processedData = processBookingData(bookings);
      
      setBookingData(processedData);
      setFilterApplied(true);
    } catch (err) {
      setError('Failed to load booking data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Process booking data for chart visualization
  const processBookingData = (bookings) => {
    if (month) {
      // If month is selected, show daily data
      const daysInMonth = new Date(year, parseInt(month), 0).getDate();
      const dailyData = Array.from({ length: daysInMonth }, (_, i) => ({
        name: `Day ${i + 1}`,
        bookings: 0
      }));
      
      bookings.forEach(booking => {
        const bookingDate = new Date(booking.bookingDate);
        const day = bookingDate.getDate();
        if (day >= 1 && day <= daysInMonth) {
          dailyData[day - 1].bookings += 1;
        }
      });
      
      return dailyData;
    } else {
      // If only year is selected, show monthly data
      const monthlyData = months.map(month => ({
        name: month.label,
        bookings: 0
      }));
      
      bookings.forEach(booking => {
        const bookingDate = new Date(booking.bookingDate);
        const monthIndex = bookingDate.getMonth();
        monthlyData[monthIndex].bookings += 1;
      });
      
      return monthlyData;
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []); // Initial fetch on component mount

  const handleFilter = (e) => {
    e.preventDefault();
    fetchBookings();
  };

  const resetFilters = () => {
    setYear(new Date().getFullYear());
    setMonth('');
    // Fetch with reset filters
    setTimeout(fetchBookings, 0);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md col-span-full">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <h3 className="text-lg font-semibold flex items-center mb-4 md:mb-0">
          <CalendarRange className="mr-2" /> Booking Analytics
        </h3>
        
        <form onSubmit={handleFilter} className="flex flex-col md:flex-row gap-3 w-full md:w-auto">
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-1">Year</label>
            <select 
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="border rounded p-2 w-full md:w-32"
            >
              {years.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 mb-1">Month (Optional)</label>
            <select 
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              className="border rounded p-2 w-full md:w-36"
            >
              <option value="">All Months</option>
              {months.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          
          <div className="flex items-end gap-2">
            <button 
              type="submit" 
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
            >
              <Filter className="w-4 h-4 mr-1" /> Apply
            </button>
            
            <button 
              type="button" 
              onClick={resetFilters}
              className="bg-gray-200 text-gray-800 px-3 py-2 rounded hover:bg-gray-300"
            >
              Reset
            </button>
          </div>
        </form>
      </div>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center p-4">{error}</div>
      ) : bookingData.length === 0 ? (
        <div className="text-gray-500 text-center p-10 border border-dashed rounded">
          {filterApplied ? 
            "No bookings found for the selected period." : 
            "Select filters and click 'Apply' to view booking data."}
        </div>
      ) : (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-500 mb-2">
            {month ? `Daily Bookings - ${months.find(m => m.value === month)?.label} ${year}` : `Monthly Bookings - ${year}`}
          </h4>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={bookingData} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
              <XAxis 
                dataKey="name" 
                angle={-45} 
                textAnchor="end" 
                height={70} 
                tick={{ fontSize: 12 }}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="bookings" name="Number of Bookings" fill="#06b6d4" />
            </BarChart>
          </ResponsiveContainer>
          
          <div className="flex justify-between items-center mt-6 px-4 py-3 bg-blue-50 rounded text-sm">
            <div>
              <span className="font-medium">Total Bookings:</span> {bookingData.reduce((sum, item) => sum + item.bookings, 0)}
            </div>
            <div>
              <span className="font-medium">Peak Period:</span> {bookingData.reduce((max, item) => item.bookings > max.bookings ? item : max, { bookings: 0 }).name}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingsChart;