import React from 'react';
import ChatBot from '../Components/ChatBot/ChatBot';
import TravelHimalayaLogo from '../assets/travel-himalayan-logo.png';
import { Link } from 'react-router-dom';
import CampaignSection from '../Components/campaign/CampaignSection';
import Footer from '../Components/footer/Footer';
import AboutUs from '../Components/AboutUS/AboutUs';
import FloatingTranslator from '../Components/MultiLanguage/FloatingTranslator';

const About = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-blue-50 mt-20">
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-[url('/path-to-himalayan-bg.jpg')] bg-cover bg-center">
        <div className="absolute inset-0 bg-black/40"></div>
        <div className="relative max-w-7xl mx-auto px-6 lg:px-8 py-20 md:py-8 text-center">
          <h1 className="text-5xl md:text-6xl font-extrabold text-white mb-2 tracking-tight animate-fade-in-down">
            Discover Your True Self with{' '}
            <span className="text-indigo-300">Mantra Mountain</span>
          </h1>
          <p className="text-2xl text-white/90 max-w-3xl mx-auto mb-2">
            Embark on a life-changing Himalayan journey that awakens your spirit and empowers mountain communities.
          </p>
          <Link to="/Packages">
            <button className="bg-indigo-600 text-white px-4 py-4 rounded-full font-semibold text-xl shadow-lg hover:bg-indigo-700 transition-all duration-150 transform hover:scale-105">
              Start Your Transformation Today
            </button>
          </Link>
        </div>
      </section>

      {/* Flipping Card Section */}
      <section className="max-w-4xl mx-auto px-6 py-6">
        <div className="perspective-1000">
          <div className="relative h-72 transform-style-3d transition-transform duration-700 hover:rotate-y-180">
            <div className="absolute w-full h-full backface-hidden bg-white rounded-2xl shadow-2xl p-8 flex flex-col justify-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-2">
                More Than Just a Trek
              </h2> 
              <p className="text-xl text-gray-700">
                A transformative Himalayan adventure that redefines your limits and uplifts local communities.
              </p>
            </div>
            <div className="absolute w-full h-full backface-hidden rotate-y-180 bg-indigo-100 rounded-2xl shadow-2xl p-8 flex flex-col justify-center">
              <h2 className="text-3xl font-bold text-indigo-900 mb-2">
                Why Choose Us?
              </h2>
              <ul className="text-xl text-gray-700 space-y-3">
                <li>✓ Explore untouched landscapes</li>
                <li>✓ Uncover your inner strength</li>
                <li>✓ Find peace in nature’s embrace</li>
                <li>✓ Support disaster relief efforts</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="max-w-7xl mx-auto px-6 py-12">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-2">
          What Sets Us Apart
        </h2>
        <div className="grid md:grid-cols-3 gap-10">
          {[
            {
              icon: '🏔️',
              title: 'Unplug & Reconnect',
              desc: 'Escape the noise and immerse yourself in the untouched beauty of the Himalayas.'
            },
            {
              icon: '👥',
              title: 'Expert-Led Journeys',
              desc: 'Our seasoned guides ensure safety and inspire personal growth every step of the way.'
            },
            {
              icon: '❤️',
              title: 'Impact That Matters',
              desc: '20% of profits fund disaster relief and sustainable development in remote villages.'
            }
          ].map((feature, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-3"
            >
              <span className="text-5xl mb-2 block">{feature.icon}</span>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {feature.title}
              </h3>
              <p className="text-lg text-gray-600">{feature.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-indigo-800 py-16">
        <div className="max-w-7xl mx-auto px-6 grid md:grid-cols-4 gap-8 text-center text-white">
          <div>
            <p className="text-5xl font-bold">500+</p>
            <p className="text-xl mt-2">Transformed Lives</p>
          </div>
          <div>
            <p className="text-5xl font-bold">20%</p>
            <p className="text-xl mt-2">Profits Donated</p>
          </div>
          <div>
            <p className="text-5xl font-bold">15+</p>
            <p className="text-xl mt-2">Years of Expertise</p>
          </div>
          <div>
            <p className="text-5xl font-bold">100%</p>
            <p className="text-xl mt-2">Customer Satisfaction</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="max-w-7xl mx-auto px-6 py-20">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-2">
          What Our Travelers Say
        </h2>
        <div className="grid md:grid-cols-2 gap-10">
          {[
            {
              quote: "This trek changed my life. The mountains, the people, the peace—it’s indescribable.",
              name: "Sarah M.",
              title: "Adventurer"
            },
            {
              quote: "The guides were incredible, and knowing my trip helped others made it even more special.",
              name: "James R.",
              title: "Nature Enthusiast"
            }
          ].map((testimonial, index) => (
            <div
              key={index}
              className="bg-gray-100 p-8 rounded-xl shadow-md"
            >
              <p className="text-lg text-gray-700 italic mb-4">“{testimonial.quote}”</p>
              <p className="text-xl font-semibold text-gray-900">{testimonial.name}</p>
              <p className="text-gray-600">{testimonial.title}</p>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-indigo-600 to-purple-600 py-16 text-center">
        <div className="max-w-4xl mx-auto px-6">
          <h2 className="text-4xl font-bold text-white mb-6">
            Ready to Begin Your Himalayan Transformation?
          </h2>
          <p className="text-xl text-white/90 mb-8">
            Join hundreds of adventurers who’ve found peace, purpose, and connection.
          </p>
          <Link to="/Packages">
            <button className="bg-white text-indigo-600 px-10 py-4 rounded-full font-semibold text-xl shadow-lg hover:bg-indigo-100 transition-all duration-300 transform hover:scale-105">
              Book Your Journey Now
            </button>
          </Link>
        </div>
      </section>

      <AboutUs />

      {/* Mantra Section */}
      <section className="max-w-5xl mx-auto px-6 py-8 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-2">Our Guiding Mantra</h2>
        <blockquote className="text-2xl text-gray-700 italic bg-white p-8 rounded-lg shadow-md">
          "Lead us from the unreal to the real<br />
          Lead us from darkness to light<br />
          Lead us from death to immortality<br />
          Aum peace, peace, peace!"
        </blockquote>
        <p className="mt-2 text-xl text-gray-600">
          This ancient wisdom inspires every step of your journey toward inner peace and clarity.
        </p>
      </section>

      {/* Partner Section */}
      <section className="max-w-7xl mx-auto px-6 py-8 flex flex-col md:flex-row items-center gap-12">
        <div className="md:w-1/2">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">
            Unmatched Expertise & Impact
          </h2>
          <p className="text-xl text-gray-600 mb-2">
            In partnership with Travel Himalaya Nepal, we deliver exceptional, life-changing adventures.
          </p>
          <p className="text-xl text-gray-600">
            Together with humanitarian allies, we empower mountain communities through disaster relief and sustainable growth.
          </p>
        </div>
        <img
          src={TravelHimalayaLogo}
          alt="Travel Himalaya Nepal"
          className="w-40 h-40 rounded-full shadow-lg md:w-48 md:h-48"
        />
      </section>

      {/* Disaster Relief Section */}
      <section className="bg-gray-100 py-20">
        <div className="max-w-5xl mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">
            Empowering Mountain Communities
          </h2>
          <p className="text-xl text-gray-600 mb-2">
            We rebuild infrastructure, deliver immediate aid, and foster sustainable recovery in remote Himalayan villages.
          </p>
          <p className="text-xl text-gray-600">
            Your journey with Mantra Mountain directly supports these resilient communities.
          </p>
        </div>
      </section>

      <ChatBot />
      <CampaignSection />
      <Footer />
    </div>
  );
};

export default About;