import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaUser, FaEnvelope, FaPhone, FaUsers, FaDollarSign, FaClock, FaDumbbell, FaMountain, FaCalendarAlt } from 'react-icons/fa';
import { AlertCircle, Shield, Check, ChevronDown, Star } from 'lucide-react';
import Select from 'react-select';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import Payment from '../Payment/Payment';

const countryOptions = [
  { value: '+1', label: '🇺🇸 +1 (USA)' },
  { value: '+44', label: '🇬🇧 +44 (UK)' },
  { value: '+91', label: '🇮🇳 +91 (India)' },
  { value: '+977', label: '🇳🇵 +977 (Nepal)' },
  { value: '+61', label: '🇦🇺 +61 (Australia)' },
  { value: '+33', label: '🇫🇷 +33 (France)' },
  { value: '+49', label: '🇩🇪 +49 (Germany)' },
  { value: '+81', label: '🇯🇵 +81 (Japan)' },
  { value: '+86', label: '🇨🇳 +86 (China)' },
  { value: '+55', label: '🇧🇷 +55 (Brazil)' },
  { value: '+27', label: '🇿🇦 +27 (South Africa)' },
  { value: '+7', label: '🇷🇺 +7 (Russia)' },
  { value: '+34', label: '🇪🇸 +34 (Spain)' },
  { value: '+39', label: '🇮🇹 +39 (Italy)' },
  { value: '+41', label: '🇨🇭 +41 (Switzerland)' },
  { value: '+46', label: '🇸🇪 +46 (Sweden)' },
  { value: '+31', label: '🇳🇱 +31 (Netherlands)' },
  { value: '+47', label: '🇳🇴 +47 (Norway)' },
  { value: '+82', label: '🇰🇷 +82 (South Korea)' },
];

const BookingCalendar = ({ packageData, onClose, onBook, currency = 'USD', themeColor = 'indigo' }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+1',
    phoneNumber: '',
    startDate: null,
    numberOfPeople: '1',
    promoCode: '',
    agreeTerms: false,
  });
  const [showPayment, setShowPayment] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');

  // Mock data
  const bookedDates = ['2025-03-20', '2025-03-25', '2025-04-01'];
  const manuallyBlockedDates = ['2025-02-25', '2025-04-10', '2025-05-15'];
  const today = new Date();
  const minDate = new Date(today);
  minDate.setDate(today.getDate() + 5);
  const maxDate = new Date(today);
  maxDate.setFullYear(today.getFullYear() + 1);

  // Number of people options
  const peopleOptions = Array.from({ length: 25 }, (_, i) => ({
    value: `${i + 1}`,
    label: `${i + 1} ${i === 0 ? 'Person' : 'People'}`,
  }));

  // Date utilities
  const isDateBlocked = (dateInput) => {
    // Convert input to a date string, ensuring local date
    const date = dateInput instanceof Date
      ? `${dateInput.getFullYear()}-${String(dateInput.getMonth() + 1).padStart(2, '0')}-${String(dateInput.getDate()).padStart(2, '0')}`
      : dateInput; // Assume it's already a string in "YYYY-MM-DD" format

    return (
      bookedDates.includes(date) ||
      manuallyBlockedDates.includes(date) ||
      new Date(date) < minDate
    );
  };
  // Form handlers
  const handleDateChange = (date) => {
    if (date) {
      // Use local date components to avoid UTC conversion issues
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      setFormData((prev) => ({ ...prev, startDate: dateString }));
    } else {
      setFormData((prev) => ({ ...prev, startDate: null }));
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
      // startDate: null ,
    }));
    setErrorMessage('');
    if (name === 'email') validateEmail(value);
  };

  const handleSelectChange = (option, { name }) => {
    setFormData((prev) => ({ ...prev, [name]: option.value }));
    setErrorMessage('');
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const applyPromoCode = () => {
    if (formData.promoCode.toUpperCase() === 'TREK10') {
      setPromoApplied(true);
      setErrorMessage('');
    } else {
      setPromoApplied(false);
      setErrorMessage('Invalid promo code');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateString = formData.startDate;
    if (!dateString || isDateBlocked(new Date(dateString))) {
      setErrorMessage('Please select an available date');
      return;
    }
    if (!formData.agreeTerms) {
      setErrorMessage('Please agree to the terms and conditions');
      return;
    }
    if (!formData.name || !formData.email || !formData.phoneNumber || !formData.numberOfPeople || emailError) {
      setErrorMessage('Please fill out all required fields correctly');
      return;
    }
    setShowPayment(true);
  };

  const handlePaymentSuccess = async (bookingResult) => {
    const modifiedPackageData = { ...packageData, packageName: packageData.title };
    const paymentData = { amount: totalAmount, status: 'success', currency };
    const bookingDetails = { ...formData, packageName: modifiedPackageData.packageName, currency };

    try {
  //  const response = await fetch('http://localhost:5000/api/verify-payment', {
      const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/verify-payment', {

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentData, bookingDetails }),
      });

      const result = await response.json();
      if (response.ok) {z
        alert(result.message);
        onBook(modifiedPackageData, formData.startDate, formData);
        onClose();
      } else {
        setErrorMessage(result.error || 'Payment verification failed');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setErrorMessage('Payment verification failed. Please try again.');
    }
  };

  // Price calculations
  const convertPrice = (price) => {
    const rates = { USD: 1, EUR: 0.92, GBP: 0.78 };
    return Math.round(price * (rates[currency] || 1));
  };

  const basePrice = convertPrice(packageData?.price * parseInt(formData.numberOfPeople || 1));
  const discount = promoApplied ? basePrice * 0.15 : 0;
  const totalAmount = basePrice - discount;

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '0.5rem',
      borderColor: '#d1d5db',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      '&:hover': { borderColor: '#a1a1aa' },
      padding: '0.5rem',
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? '#e0e7ff' : 'white',
      color: '#1f2937',
      cursor: 'pointer',
    }),
  };

  if (!packageData) {
    return <div className="text-center text-gray-600">Loading package details...</div>;
  }

  return (
    <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
      >
        {/* Header */}
        <div className={`bg-gradient-to-r from-${themeColor}-600 to-green-600 p-6 text-white`}>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">{packageData?.title}</h2>
            <button onClick={onClose} className="text-white hover:text-gray-200 transition-colors text-2xl">×</button>
          </div>
          <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4 text-sm sm:text-base">
            <div className="flex items-center"><FaClock className="mr-2" /> {packageData?.duration}</div>
            <div className="flex items-center"><FaDumbbell className="mr-2" /> {packageData?.difficulty}</div>
            <div className="flex items-center"> {currency} {convertPrice(packageData?.price)}/person</div>
            <div className="flex items-center"><FaMountain className="mr-2" /> Nepal</div>
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="flex items-center justify-center gap-4 text-gray-600 mb-6">
            <div className="flex items-center gap-2"><FaCheckCircle className="text-green-500" /> Booking Details</div>
            <ChevronDown className="w-4 h-4" />
            <div className="flex items-center gap-2"><FaDollarSign /> Payment</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
              <div className="relative">
                <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 shadow-sm"
                  placeholder="Enter your full name"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <div className="relative">
                <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className={`w-full pl-10 pr-4 py-3 rounded-lg border ${emailError ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 shadow-sm`}
                  placeholder="Enter your email"
                />
              </div>
              {emailError && <p className="text-red-500 text-xs mt-1">{emailError}</p>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
              <div className="flex gap-2">
                <div className="relative w-1/3">
                  <Select
                    name="countryCode"
                    options={countryOptions}
                    value={countryOptions.find(option => option.value === formData.countryCode)}
                    onChange={(option) => handleSelectChange(option, { name: 'countryCode' })}
                    styles={customSelectStyles}
                    className="text-sm"
                    placeholder="+1"
                  />
                </div>
                <div className="relative flex-1">
                  <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 shadow-sm"
                    placeholder="Enter your number"
                  />
                </div>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Number of People</label>
              <div className="relative">
                <Select
                  name="numberOfPeople"
                  options={peopleOptions}
                  value={peopleOptions.find(option => option.value === formData.numberOfPeople)}
                  onChange={(option) => handleSelectChange(option, { name: 'numberOfPeople' })}
                  styles={customSelectStyles}
                  className="text-sm"
                  placeholder="1 Person"
                />
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Start Date <FaCalendarAlt className="inline ml-2 text-indigo-600" />
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={formData.startDate ? new Date(`${formData.startDate}T00:00:00`) : null}
                onChange={handleDateChange}
                minDate={minDate}
                maxDate={maxDate}
                shouldDisableDate={isDateBlocked}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0.5rem', backgroundColor: '#fff', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)' } }}
                  />
                )}
                renderDay={(date, selectedDate, dayInCurrentMonth, dayComponent) => {
                  const dateString = date.toISOString().split('T')[0];
                  const isBlocked = isDateBlocked(date);
                  const spots = isBlocked ? 0 : Math.floor(Math.random() * 10) + 1;
                  return (
                    <div className={`flex flex-col items-center ${isBlocked ? 'text-red-500' : 'text-green-600'}`}>
                      {dayComponent}
                      {!isBlocked && dayInCurrentMonth && <span className="text-xs">{spots} left</span>}
                    </div>
                  );
                }}
              />
            </LocalizationProvider>
            {formData.startDate && (
              <p className="text-sm text-gray-600">
                {isDateBlocked(new Date(formData.startDate))
                  ? 'Not available'
                  : `${Math.floor(Math.random() * 10) + 1} spots left`}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <button
              type="button"
              onClick={() => setShowPromo(!showPromo)}
              className="flex items-center gap-2 text-indigo-600 hover:underline text-sm"
            >
              <FaDollarSign /> Add Promo Code
            </button>
            {showPromo && (
              <div className="flex gap-2">
                <input
                  type="text"
                  name="promoCode"
                  value={formData.promoCode}
                  onChange={handleChange}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 shadow-sm text-sm"
                  placeholder="e.g., TREK10"
                />
                <button
                  type="button"
                  onClick={applyPromoCode}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors shadow-md text-sm"
                >
                  Apply
                </button>
              </div>
            )}
            {promoApplied && <p className="text-green-600 text-sm">Promo code applied! 10% off</p>}
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-md space-y-2">
            <h3 className="text-lg font-semibold text-gray-900">Price Breakdown</h3>
            <div className="flex justify-between text-gray-600">
              <span>Base Price ({formData.numberOfPeople || 1} x {currency} {convertPrice(packageData?.price)})</span>
              <span>{currency} {basePrice}</span>
            </div>
            {promoApplied && (
              <div className="flex justify-between text-green-600">
                <span>Promo Discount (10%)</span>
                <span>-{currency} {discount}</span>
              </div>
            )}
            <div className="flex justify-between text-gray-900 font-bold">
              <span>Total Amount</span>
              <span>{currency} {totalAmount}</span>
            </div>
          </div>

          <div className="flex justify-between items-center text-gray-600 text-sm">
            <div className="flex items-center gap-2"><Shield className="text-green-500" /> Secure Booking</div>
            <div className="flex items-center gap-2"><Star className="text-yellow-400" /> Rated 4.9/5</div>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={handleChange}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label className="text-sm text-gray-600">
              I agree to the <a href="#" className="text-indigo-600 hover:underline">terms and conditions</a>
            </label>
          </div>

          {errorMessage && (
            <div className="flex items-center gap-2 text-red-600 bg-red-100 p-3 rounded-lg">
              <AlertCircle className="w-5 h-5" /> {errorMessage}
            </div>
          )}

          <div className="flex justify-between items-center gap-4">
            <button
              type="submit"
              disabled={!formData.startDate || isDateBlocked(formData.startDate) || !formData.agreeTerms}
              className={`w-full px-6 py-3 bg-gradient-to-r from-${themeColor}-600 to-green-600 text-white font-semibold rounded-full hover:from-${themeColor}-700 hover:to-green-700 transition-colors shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed`}
            >
              Confirm Booking
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-3 bg-gray-200 text-gray-700 font-semibold rounded-full hover:bg-gray-300 transition-colors"
            >
              Cancel
            </button>
          </div>

          <p className="text-center text-sm text-gray-600 mt-4">
            Need help? <a href="info@mantramountain.com" className="text-indigo-600 hover:underline">Contact us</a>
          </p>
        </form>

        {showPayment && (
          <Payment
            amount={totalAmount}
            currency={currency}
            onPaymentSuccess={handlePaymentSuccess}
            onPaymentCancel={() => setShowPayment(false)}
            bookingDetails={{
              packageData: { ...packageData, packageName: packageData.title },
              date: formData.startDate,
              formData: {
                name: formData.name,
                email: formData.email,
                contactNumber: `${formData.countryCode}${formData.phoneNumber}`,
              },
              numberOfPeople: parseInt(formData.numberOfPeople || 1),
            }}
          />
        )}
      </motion.div>
    </div>
  );
};

BookingCalendar.propTypes = {
  packageData: PropTypes.shape({
    title: PropTypes.string,
    duration: PropTypes.string,
    difficulty: PropTypes.string,
    price: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired,
  currency: PropTypes.string,
  themeColor: PropTypes.string,
};

export default BookingCalendar;