import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28DFF", "#FF5678"];

const BlogCountList = () => {
  const [blogCounts, setBlogCounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogCounts = async () => {
      try {
        const response = await fetch("https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogsByAuthor");
        // const response = await fetch("http://localhost:5000/api/blogsByAuthor");
        const data = await response.json();
        if (data.success) {
          setBlogCounts(data.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogCounts();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>Error: {error}</p>;

  return (
    
      <div>
      <div className="flex flex-row  gap-5 border-s-black">
        <div className="">
          <strong>
      <h2 className="text-2xl text-green-700 p-2 stroke-black w-48">Blog Posts by Authors</h2>
  </strong>
      <ul>
        {blogCounts.map((author) => (
          <li key={author.authorId}>
            <strong>{author.authorName} 
              {/* ({author.role}) */}
              </strong> 
            {/* - {author.email} -  */}
            =
            <b>{author.blogCount} posts</b>
          </li>
        ))}
      </ul>
<div></div>
</div>
<div>
      <h2 className="text-2xl">Blog Count Distribution chart</h2>
      <PieChart width={400} height={400}>
  <Pie
    data={blogCounts}
    dataKey="blogCount"
    nameKey="authorName"  // ✅ Ensure this matches your API data
    cx="50%"
    cy="50%"
    outerRadius={150}
    fill="#8884d8"
    label
  >
    {blogCounts.map((entry, index) => (
      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
    ))}
  </Pie>
  <Tooltip />
</PieChart>

      
</div>
    </div>
    </div>
  );
};

export default BlogCountList;
