import React from 'react';

const MessagesSection = ({ messages }) => {
  if (!messages.length) return null;

  return (
    <div className="bg-white p-6 rounded-lg shadow-md border">
      <h3 className="text-lg font-semibold mb-4">Recent Contact Messages</h3>
      <div className="space-y-4">
        {messages.map((message, index) => (
          <div key={index} className="border-b pb-4 last:border-0">
            <div className="flex justify-between items-start">
              <div>
                <p className="font-medium">{message.name}</p>
                <p className="text-sm text-gray-500">{message.email}</p>
              </div>
              <span className="text-xs text-gray-500">
                {new Date(message.date).toLocaleDateString()}
              </span>
            </div>
            <p className="mt-2 text-sm">{message.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MessagesSection; 