import React, { useState, useEffect, useRef } from 'react';
import beepSound from './../../assets/beep.mp3';
import robo from '../../assets/robo.png';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({ experience: null });
  const [packages, setPackages] = useState([]);
  const messagesEndRef = useRef(null);

  const API_URL_PACKAGES = 'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all';
  const API_KEY = 'sk-a5d0e652d8b1498298ebbec288607016'; // No API key needed if the endpoint is public

  useEffect(() => {
    scrollToBottom();
    if (isOpen && messages.length === 0) {
      fetchPackages();
    }
  }, [isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const playBeep = () => {
    const audio = new Audio(beepSound);
    audio.play();
  };

  const addMessage = (text, isUser) => {
    setMessages(prev => [...prev, { text, user: isUser }]);
  };

  const fetchPackages = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(API_URL_PACKAGES);
      if (!response.ok) throw new Error('Failed to fetch packages');
      const data = await response.json();
      setPackages(data); // Assuming data is an array of packages
      addMessage("Namaste! 🙏 I'm your Nepal trekking guide. What is your trekking experience level? (Beginner, Intermediate, or Advanced)", false);
    } catch (error) {
      console.error('Error fetching packages:', error);
      addMessage("Sorry, I couldn’t fetch the trekking packages right now. Please try again later.", false);
    } finally {
      setIsLoading(false);
    }
  };

  const getRecommendation = (experience) => {
    let recommendedPackage = null;

    if (experience.includes("beginner")) {
      // Recommend shortest duration, lowest altitude, moderate difficulty
      recommendedPackage = packages.find(pkg => 
        pkg.difficulty === "Moderate" && 
        pkg.duration.split(' ')[0] <= 7 && // Assuming duration format is "X Days"
        (pkg.dailyAscent ? parseInt(pkg.dailyAscent.split('-')[0]) <= 1000 : true) // Rough altitude check
      ) || packages[0]; // Fallback to first package
    } else if (experience.includes("intermediate")) {
      // Recommend moderate duration and altitude
      recommendedPackage = packages.find(pkg => 
        pkg.difficulty === "Moderate" && 
        pkg.duration.split(' ')[0] <= 10 && 
        (pkg.dailyAscent ? parseInt(pkg.dailyAscent.split('-')[0]) <= 1500 : true)
      ) || packages[1]; // Fallback to second package
    } else if (experience.includes("advanced")) {
      // Recommend longest duration, highest altitude, challenging difficulty
      recommendedPackage = packages.reduce((max, pkg) => 
        parseInt(pkg.duration.split(' ')[0]) > parseInt(max.duration.split(' ')[0]) && 
        (pkg.difficulty.includes("Challenging") || pkg.altitude > 4000) ? pkg : max
      , packages[0]) || packages[2]; // Fallback to third package
    }

    return recommendedPackage ? 
      `Based on your experience, I recommend the ${recommendedPackage.title}: ${recommendedPackage.duration}, Difficulty: ${recommendedPackage.difficulty}, Altitude: ${recommendedPackage.altitude || 'Varies'}. Price: $${recommendedPackage.price}` :
      "Sorry, I couldn’t find a suitable package. Please consider Poon Hill Trek or contact us for more options.";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    addMessage(input, true);
    setInput('');
    setIsLoading(true);

    if (!userProfile.experience) {
      setUserProfile(prev => ({ ...prev, experience: input.toLowerCase() }));
      const recommendation = getRecommendation(input.toLowerCase());
      addMessage(recommendation, false);
    } else {
      addMessage("Thanks for your input! I’ve already recommended a package based on your experience.", false);
    }

    setIsLoading(false);
  };

  const toggleChatbot = () => {
    playBeep();
    setIsOpen(!isOpen);
  };

  return (
    <div className={`fixed bottom-4 right-4 z-50 flex flex-col items-end ${isOpen ? 'w-[350px]' : 'w-auto'}`}>
      <button 
        className="w-16 h-16 rounded-full bg-white shadow-lg flex items-center justify-center hover:bg-gray-50 transition-all duration-300 border border-gray-200"
        onClick={toggleChatbot}
        aria-label={isOpen ? 'Close chat' : 'Open chat'}
      >
        {isOpen ? '✕' : <img src={robo} alt="Chatbot" className="h-14 w-14" />}
      </button>
      
      {isOpen && (
        <div className="mt-4 bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden w-full">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-xl font-bold text-gray-800">Nepal Trek Advisor</h2>
            <p className="text-sm text-gray-600">Your Personal Trekking Guide</p>
          </div>
          
          <div className="h-[400px] overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`p-3 rounded-lg ${
                  message.user 
                    ? 'bg-blue-500 text-white ml-auto max-w-[80%]' 
                    : 'bg-gray-100 text-gray-800 mr-auto max-w-[80%]'
                }`}
              >
                {message.text}
              </div>
            ))}
            {isLoading && (
              <div className="flex space-x-2 p-3 bg-gray-100 rounded-lg w-20">
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-100"></div>
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          
          <form onSubmit={handleSubmit} className="p-4 border-t border-gray-200">
            <div className="flex space-x-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your response..."
                disabled={isLoading}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button 
                type="submit" 
                disabled={isLoading}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
// import React, { useState, useEffect, useRef } from 'react';
// import beepSound from './../../assets/beep.mp3';
// import robo from '../../assets/robo.png';

// const predefinedResponses = {
//   beginner: {
//     recommendations: [
//       "For beginners, I'd recommend the Langtang Valley Trek (3870m). It's less crowded than Everest and Annapurna, with gentle slopes and beautiful landscapes.",
//       "The Poon Hill Trek (3210m) would also be perfect - it's short, sweet, and offers stunning mountain views!"
//     ]
//   },
//   intermediate: {
//     recommendations: [
//       "For intermediate trekkers, the Annapurna Base Camp Trek (4130m) is ideal. It offers a great mix of challenge and cultural experiences.",
//       "The Mardi Himal Trek (4500m) is another excellent choice - less crowded but equally beautiful!"
//     ]
//   },
//   advanced: {
//     recommendations: [
//       "For advanced trekkers, the Everest Base Camp Trek (5364m) is the ultimate challenge. The views are unmatched!",
//       "The Manaslu Circuit Trek (5160m) is another challenging option with incredible diversity in landscapes."
//     ]
//   }
// };

// const Chatbot = () => {
//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [userProfile, setUserProfile] = useState({
//     maxAltitude: null,
//     experience: null,
//     preferences: null
//   });
//   const messagesEndRef = useRef(null);

//   useEffect(() => {
//     scrollToBottom();
//     if (isOpen && messages.length === 0) {
//       addMessage("Namaste! 🙏 I'm your Nepal trekking guide. To recommend the perfect trek, could you tell me the highest altitude you've trekked or hiked before (in meters)?", false);
//     }
//   }, [messages, isOpen]);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   const playBeep = () => {
//     const audio = new Audio(beepSound);
//     audio.play();
//   };

//   const addMessage = (text, isUser) => {
//     setMessages(prev => [...prev, { text, user: isUser }]);
//   };

//   const analyzeUserResponse = (input) => {
//     const altitude = parseInt(input.match(/\d+/)?.[0]);
//     if (altitude) {
//       setUserProfile(prev => ({ ...prev, maxAltitude: altitude }));
//       return {
//         nextQuestion: "Great! And how would you rate your trekking experience? (Beginner, Intermediate, or Advanced)",
//         updateProfile: true
//       };
//     }
    
//     if (input.toLowerCase().includes("beginner") || 
//         input.toLowerCase().includes("intermediate") || 
//         input.toLowerCase().includes("advanced")) {
//       setUserProfile(prev => ({ ...prev, experience: input.toLowerCase() }));
//       return {
//         nextQuestion: "What interests you more: cultural experiences, challenging peaks, or scenic landscapes?",
//         updateProfile: true
//       };
//     }

//     return { nextQuestion: null, updateProfile: false };
//   };

//   const getRecommendation = async (userInput) => {
//     const experience = userProfile.experience || 'beginner';
//     return predefinedResponses[experience].recommendations.join('\n\n');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!input.trim()) return;

//     addMessage(input, true);
//     setInput('');
//     setIsLoading(true);

//     const analysis = analyzeUserResponse(input);
//     if (analysis.updateProfile) {
//       addMessage(analysis.nextQuestion, false);
//     } else {
//       const recommendation = await getRecommendation(input);
//       addMessage(recommendation, false);
//     }

//     setIsLoading(false);
//   };

//   const toggleChatbot = () => {
//     playBeep();
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className={`fixed bottom-4 right-4 z-50 flex flex-col items-end ${isOpen ? 'w-[350px]' : 'w-auto'}`}>
//       <button 
//         className="w-16 h-16 rounded-full bg-white shadow-lg flex items-center justify-center hover:bg-gray-50 transition-all duration-300 border border-gray-200"
//         onClick={toggleChatbot}
//         aria-label={isOpen ? 'Close chat' : 'Open chat'}
//       >
//         {isOpen ? '✕' : <img src={robo} alt="Chatbot" className="h-14 w-14" />}
//       </button>
      
//       {isOpen && (
//         <div className="mt-4 bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden w-full">
//           <div className="p-4 border-b border-gray-200">
//             <h2 className="text-xl font-bold text-gray-800">Nepal Trek Advisor</h2>
//             <p className="text-sm text-gray-600">Your Personal Trekking Guide</p>
//           </div>
          
//           <div className="h-[400px] overflow-y-auto p-4 space-y-4">
//             {messages.map((message, index) => (
//               <div
//                 key={index}
//                 className={`p-3 rounded-lg ${
//                   message.user 
//                     ? 'bg-blue-500 text-white ml-auto max-w-[80%]' 
//                     : 'bg-gray-100 text-gray-800 mr-auto max-w-[80%]'
//                 }`}
//               >
//                 {message.text}
//               </div>
//             ))}
//             {isLoading && (
//               <div className="flex space-x-2 p-3 bg-gray-100 rounded-lg w-20">
//                 <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
//                 <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-100"></div>
//                 <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
//               </div>
//             )}
//             <div ref={messagesEndRef} />
//           </div>
          
//           <form onSubmit={handleSubmit} className="p-4 border-t border-gray-200">
//             <div className="flex space-x-2">
//               <input
//                 type="text"
//                 value={input}
//                 onChange={(e) => setInput(e.target.value)}
//                 placeholder="Type your response..."
//                 disabled={isLoading}
//                 className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//               />
//               <button 
//                 type="submit" 
//                 disabled={isLoading}
//                 className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
//               >
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Chatbot;