import React from 'react';
import './Hero.css';
// import arrow from '../../assets/arrow.png';
import { Link } from 'react-router-dom';
// import Globe from '../../Components/globe/globe';

const Hero = ({ heroData, setHeroCount, heroCount }) => {
  return (
    <div className="hero">
      <div className="hero-text">{heroData.text1}</div>
      <div className="hero-text">{heroData.text2}</div>
      <div className="hero-subtext">{heroData.subtext}</div>
      
      <div className="hero-globe">
        {/* <Globe /> */}
      </div>
      <div className="hero-dots">
        <ul>
          {[0, 1, 2].map((index) => (
            <li
              key={index}
              onClick={() => setHeroCount(index)}
              className={`hero-dot ${heroCount === index ? 'active' : ''}`}
            ></li>
          ))}
        </ul>
        <div className="hero-explore">
        <Link to="/Packages" className="hero-explore-link" onClick={() => setHeroCount(heroCount)}>
          <span className='inline-flex'>EXPLORE THE PACKAGES
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="explore">
  <path fill="none" d="M0 0h24v24H0V0z"></path>
  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5l7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z"></path>
</svg>
          </span>
          {/* <img src={arrow} alt="" aria-hidden="true" /> */}
        </Link>
      </div>
      </div>
    </div>
  );
};

export default Hero;