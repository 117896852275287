import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaSearch, FaFilter, FaEdit, FaTrash } from 'react-icons/fa';
import { Star, Clock, Mountain, ChevronDown, ChevronUp } from 'lucide-react';
import Footer from '../../footer/Footer';
// import CampaignSection from '../campaign/CampaignSection';

const AdminBlogList = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsPerLoad] = useState(6);
  const [currentCount, setCurrentCount] = useState(itemsPerLoad);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // Fetch blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('adminToken');
        if (!token) throw new Error('Admin token not found. Please log in.');
        
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/all', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (Array.isArray(response.data)) {
          setBlogs(response.data);
          setFilteredBlogs(response.data);
          setVisibleBlogs(response.data.slice(0, itemsPerLoad));
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError(error.response?.data?.message || error.message || 'Failed to load blogs');
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  // Filter blogs
  const handleFilterApply = () => {
    const filtered = blogs.filter((blog) => {
      const matchesSearch = blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            (blog.content && blog.content.toLowerCase().includes(searchQuery.toLowerCase()));
      return matchesSearch;
    });
    setFilteredBlogs(filtered);
    setVisibleBlogs(filtered.slice(0, Math.min(itemsPerLoad, filtered.length)));
    setCurrentCount(Math.min(itemsPerLoad, filtered.length));
  };

  useEffect(() => {
    handleFilterApply();
  }, [searchQuery]);

  // Load more blogs
  const loadMore = () => {
    const newCount = currentCount + itemsPerLoad;
    setVisibleBlogs(filteredBlogs.slice(0, newCount));
    setCurrentCount(newCount);
  };

  // Delete blog
  const handleDeleteBlog = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (!confirmDelete) return;

    const token = localStorage.getItem('adminToken');
    if (!token) {
      alert('You are not authorized. Please log in as an admin.');
      return;
    }

    try {
      const response = await axios.delete(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBlogs(blogs.filter((blog) => blog._id !== id));
      setFilteredBlogs(filteredBlogs.filter((blog) => blog._id !== id));
      setVisibleBlogs(visibleBlogs.filter((blog) => blog._id !== id));
      alert(response.data.message || 'Blog deleted successfully!');
    } catch (error) {
      alert(error.response?.data?.message || 'Failed to delete blog.');
      console.error('Error deleting blog:', error);
    }
  };

  // Update blog
  const handleUpdateBlog = (slug) => navigate(`/update-blog/${slug}`);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-blue-100">
        <motion.div
          animate={{ scale: [1, 1.2, 1], rotate: [0, 360] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
        >
          <Mountain className="w-16 h-16 text-indigo-600" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-blue-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 py-12 px-4 sm:px-6 lg:px-8 mt-20">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-center"
      >
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 tracking-tight">
          <span className="bg-gradient-to-r from-green-400 via-blue-500 to-indigo-600 bg-clip-text text-transparent">
            Admin Blog Dashboard
          </span>
        </h1>
        <p className="text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto">
          Manage your blog posts—update or delete with ease.
        </p>
      </motion.div>

      <div className="max-w-7xl mx-auto">
        {/* Top Bar Filters */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-transparent rounded-lg sm:p-1 mb-8"
        >
          <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
            <div className="relative w-full sm:w-1/2">
              <input
                type="text"
                placeholder="Search blogs..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white shadow-sm text-gray-700 placeholder-gray-400 transition-all"
              />
              <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-600 to-green-600 text-white rounded-full hover:from-indigo-700 hover:to-green-700 transition-colors shadow-md"
            >
              <FaFilter className="w-4 h-4" /> Filters {showFilters ? <ChevronUp /> : <ChevronDown />}
            </button>
            <button
              onClick={() => navigate('/CreateBlog')}
              className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-green-600 to-indigo-600 text-white rounded-full hover:from-green-700 hover:to-indigo-700 transition-colors shadow-md"
            >
              + Create Blog
            </button>
          </div>

          {showFilters && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.3 }}
              className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4"
            >
              {/* Add filter options here if needed */}
            </motion.div>
          )}
        </motion.div>

        {/* Featured Blog
        {filteredBlogs.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mb-8"
          >
            <Link to={`/blog/${filteredBlogs[0].slug}`} className="block">
              <div className="relative rounded-3xl shadow-2xl overflow-hidden bg-white transition-transform duration-300 hover:shadow-3xl hover:scale-105">
                <div className="relative h-64 sm:h-80 md:h-96">
                  <img
                    src={filteredBlogs[0].images[0] || filteredBlogs[0].image}
                    alt={filteredBlogs[0].title}
                    className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent"></div>
                  <div className="absolute top-4 right-4 bg-yellow-400 text-white px-3 py-1 rounded-full flex items-center gap-2 shadow-md">
                    <Star className="w-5 h-5" /> Featured Blog
                  </div>
                  <div className="absolute bottom-4 left-4 right-4 text-white">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 line-clamp-2 drop-shadow-lg">
                      {filteredBlogs[0].title}
                    </h2>
                    <p className="text-lg font-semibold drop-shadow-md">{filteredBlogs[0].author?.username || 'Unknown'}</p>
                  </div>
                </div>
              </div>
            </Link>
          </motion.div>
        )} */}

        {/* Blog Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {visibleBlogs.map((blog, index) => (
            <motion.div
              key={blog._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 hover:shadow-xl hover:scale-105"
            >
              <div className="relative h-56 sm:h-60 overflow-hidden">
                <img
                  src={blog.images[0] || blog.image}
                  alt={blog.title}
                  className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                <div className="absolute top-2 left-2 flex items-center bg-white/90 px-2 py-1 rounded-full text-sm font-semibold text-indigo-600 shadow-md">
                  <Star className="w-4 h-4 text-yellow-400 mr-1" /> 4.9
                </div>
                <div className="absolute bottom-4 left-4 text-white">
                  <p className="text-sm font-medium">Author</p>
                  <p className="text-xl sm:text-2xl font-bold">{blog.author?.username || 'Unknown'}</p>
                </div>
              </div>
              <div className="p-4 sm:p-5">
                <h2 className="text-lg sm:text-xl font-bold text-gray-800 mb-3 line-clamp-2">
                  {blog.title}
                </h2>
                <div className="mt-4 flex justify-between items-center">
                  <button
                    onClick={() => handleUpdateBlog(blog.slug)}
                    className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors"
                  >
                    <FaEdit className="w-4 h-4 mr-1" /> Update
                  </button>
                  <button
                    onClick={() => handleDeleteBlog(blog._id)}
                    className="flex items-center text-red-600 hover:text-red-800 transition-colors"
                  >
                    <FaTrash className="w-4 h-4 mr-1" /> Delete
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
          {visibleBlogs.length === 0 && (
            <div className="col-span-full text-center text-gray-600 text-lg sm:text-xl py-12">
              No blogs found matching your criteria. Try adjusting your search!
            </div>
          )}
        </div>

        {/* Load More */}
        {visibleBlogs.length < filteredBlogs.length && (
          <div className="text-center mt-10 sm:mt-12">
            <button
              onClick={loadMore}
              className="px-6 py-3 text-sm bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-lg shadow-md hover:from-indigo-700 hover:to-green-700 transition-all duration-300"
            >
              Load More Blogs
            </button>
          </div>
        )}

        {/* <CampaignSection /> */}
      </div>

      <Footer />
    </div>
  );
};

export default AdminBlogList;