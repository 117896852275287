import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, XCircle, Mountain, Clock, Map } from 'lucide-react';

const UpdatePackage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    country: '',
    region: '',
    duration: '',
    difficulty: '',
    dailyDistance: '',
    dailyAscent: '',
    price: '',
    included: '',
    excluded: '',
    itinerary: [],
  });
  const [originalData, setOriginalData] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`);
        
        const data = response.data;
        console.log('Fetched data:', data);
        setFormData({
          ...data,
          included: data.included.join(', '),
          excluded: data.excluded.join(', '),
        });
        setOriginalData(data);
      } catch (err) {
        console.error('Error fetching package data:', err);
        setError(err.response?.data?.message || 'Package not found');
      } finally {
        setLoading(false);
      }
    };
    if (slug) fetchPackageData();
  }, [slug]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleItineraryChange = (index, field, value) => {
    const updatedItinerary = [...formData.itinerary];
    updatedItinerary[index] = { ...updatedItinerary[index], [field]: value };
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  const addItineraryDay = () => {
    setFormData((prevData) => ({
      ...prevData,
      itinerary: [
        ...prevData.itinerary,
        {
          day: prevData.itinerary.length + 1,
          title: '',
          description: '',
          duration: '',
          distance: '',
          ascent: '',
          descent: '',
        },
      ],
    }));
  };

  const removeItineraryDay = (index) => {
    const updatedItinerary = formData.itinerary.filter((_, i) => i !== index);
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('adminToken');
      if (!token) throw new Error('No authentication token found. Please login.');
      console.log('Admin token:', token);

      const totalSize = files.reduce((sum, file) => sum + file.size, 0, 0 );
      if (totalSize > 50 * 1024 * 1024) throw new Error('Total file size exceeds 50MB.');

      const submitFormData = new FormData();
      // Include all fields to satisfy backend requirements
      Object.keys(formData).forEach((key) => {
        if (key === 'itinerary') {
          submitFormData.append('itinerary', JSON.stringify(formData.itinerary));
        } else if (key === 'included' || key === 'excluded') {
          const values = formData[key].split(',').map((item) => item.trim());
          submitFormData.append(key, JSON.stringify(values));
        } else {
          submitFormData.append(key, formData[key]);
        }
      });
      files.forEach((file) => submitFormData.append('images', file));

      // Log FormData entries for debugging
      for (let [key, value] of submitFormData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await axios.put(
        `https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`,
        // `http://localhost:5000/api/package/${slug}`,

        submitFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Update response:', response.data);

      setOriginalData(response.data);
      alert('Package updated successfully!');
      const newSlug = response.data.slug;
      if (newSlug !== slug) {
        navigate(`/update-package/${newSlug}`);
      } else {
        navigate('/packages');
      }
    } catch (err) {
      console.error('Error updating package:', err);
      setError(err.response?.data?.message || err.message || 'Failed to update package.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-blue-100">
        <motion.div
          animate={{ scale: [1, 1.2, 1], rotate: [0, 360] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
        >
          <Mountain className="w-16 h-16 text-indigo-600" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-blue-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          {error} <button onClick={() => navigate(-1)} className="ml-4 text-indigo-600 underline">Go Back</button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 via-blue-50 to-indigo-100 pt-20 pb-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-3xl shadow-xl p-6 sm:p-8 mb-8 border border-gray-100"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-6 tracking-tight">
            Update Package: {originalData?.title}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.title}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Country</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.country}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Region</label>
                <input
                  type="text"
                  name="region"
                  value={formData.region}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.region}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Duration</label>
                <input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.duration}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Difficulty</label>
                <select
                  name="difficulty"
                  value={formData.difficulty}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                >
                  <option value="">Select Difficulty</option>
                  <option value="Easy">Easy</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Hard">Hard</option>
                </select>
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.difficulty}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Daily Distance</label>
                <input
                  type="text"
                  name="dailyDistance"
                  value={formData.dailyDistance}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.dailyDistance}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Daily Ascent</label>
                <input
                  type="text"
                  name="dailyAscent"
                  value={formData.dailyAscent}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.dailyAscent}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Price</label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  required
                />
                {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.price}</p>}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                rows="4"
                required
              />
              {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.description}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">What’s Included (comma separated)</label>
              <textarea
                name="included"
                value={formData.included}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                rows="3"
                required
              />
              {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.included.join(', ')}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">What’s Excluded (comma separated)</label>
              <textarea
                name="excluded"
                value={formData.excluded}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                rows="3"
                required
              />
              {originalData && <p className="mt-1 text-xs text-gray-500">Previous: {originalData.excluded.join(', ')}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Upload New Images (Max 20)</label>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
              />
              <p className="mt-1 text-sm text-gray-500">Selected files: {files.length}</p>
              {originalData && originalData.overallImages.length > 0 && (
                <p className="mt-1 text-xs text-gray-500">Previous Images: {originalData.overallImages.length} uploaded</p>
              )}
            </div>

            <div className="space-y-4">
              <h3 className="text-xl font-bold text-gray-900 tracking-tight">Itinerary</h3>
              {formData.itinerary.map((day, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="bg-gray-50 rounded-xl p-4 shadow-sm space-y-4"
                >
                  <h4 className="text-lg font-semibold text-gray-900">Day {day.day}</h4>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                      type="text"
                      value={day.title}
                      onChange={(e) => handleItineraryChange(index, 'title', e.target.value)}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                      required
                    />
                    {originalData && originalData.itinerary[index] && (
                      <p className="mt-1 text-xs text-gray-500">Previous: {originalData.itinerary[index].title}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                      value={day.description}
                      onChange={(e) => handleItineraryChange(index, 'description', e.target.value)}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                      required
                    />
                    {originalData && originalData.itinerary[index] && (
                      <p className="mt-1 text-xs text-gray-500">Previous: {originalData.itinerary[index].description}</p>
                    )}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Duration</label>
                      <input
                        type="text"
                        value={day.duration}
                        onChange={(e) => handleItineraryChange(index, 'duration', e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                        required
                      />
                      {originalData && originalData.itinerary[index] && (
                        <p className="mt-1 text-xs text-gray-500">Previous: {originalData.itinerary[index].duration}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Distance</label>
                      <input
                        type="text"
                        value={day.distance}
                        onChange={(e) => handleItineraryChange(index, 'distance', e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                        required
                      />
                      {originalData && originalData.itinerary[index] && (
                        <p className="mt-1 text-xs text-gray-500">Previous: {originalData.itinerary[index].distance}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Ascent</label>
                      <input
                        type="text"
                        value={day.ascent}
                        onChange={(e) => handleItineraryChange(index, 'ascent', e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                        required
                      />
                      {originalData && originalData.itinerary[index] && (
                        <p className="mt-1 text-xs text-gray-500">Previous: {originalData.itinerary[index].ascent}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Descent</label>
                      <input
                        type="text"
                        value={day.descent}
                        onChange={(e) => handleItineraryChange(index, 'descent', e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                        required
                      />
                      {originalData && originalData.itinerary[index] && (
                        <p className="mt-1 text-xs text-gray-500">Previous: {originalData.itinerary[index].descent}</p>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => removeItineraryDay(index)}
                    className="text-red-600 hover:text-red-800 text-sm font-medium"
                  >
                    Remove Day
                  </button>
                </motion.div>
              ))}
              <button
                type="button"
                onClick={addItineraryDay}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-full hover:from-indigo-700 hover:to-green-700 transition-all duration-300 shadow-md"
              >
                Add Itinerary Day
              </button>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full px-6 py-3 bg-gradient-to-r ${
                loading ? 'from-gray-400 to-gray-500' : 'from-indigo-600 to-green-600'
              } text-white font-semibold rounded-full hover:from-indigo-700 hover:to-green-700 transition-all duration-300 shadow-md hover:shadow-xl`}
            >
              {loading ? 'Updating...' : 'Update Package'}
            </button>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default UpdatePackage;