import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import Confetti from 'react-dom-confetti';
import { FaGlobe, FaMapMarkerAlt, FaWalking, FaHeart, FaChevronDown, FaChevronUp, FaSearch, FaFilter } from 'react-icons/fa';
import { LiaCalendarDaySolid } from "react-icons/lia";
import { Star } from 'lucide-react';
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import CampaignSection from '../campaign/CampaignSection';
import Footer from '../footer/Footer';

const PackageList = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [visiblePackages, setVisiblePackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [itemsPerLoad] = useState(6);
  const [currentCount, setCurrentCount] = useState(itemsPerLoad);
  const [wishlist, setWishlist] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [quickView, setQuickView] = useState(null);
  const [currency, setCurrency] = useState('USD');
  const [searchQuery, setSearchQuery] = useState('');
  const [duration, setDuration] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [region, setRegion] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // Fetch packages
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all');
        if (Array.isArray(response.data)) {
          setPackages(response.data);
          setFilteredPackages(response.data);
          setVisiblePackages(response.data.slice(0, itemsPerLoad));
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
        setError(error.response?.data?.message || 'Failed to load packages');
      } finally {
        setLoading(false);
      }
    };
    fetchPackages();
  }, []);

  // Filter packages
  const handleFilterApply = () => {
    const filtered = packages.filter((pkg) => {
      const matchesSearch = pkg.title.toLowerCase().includes(searchQuery.toLowerCase() || '');
      const matchesDuration = !duration || pkg.duration === duration;
      const matchesDifficulty = !difficulty || pkg.difficulty === difficulty;
      const matchesRegion = !region || pkg.region.toLowerCase().includes(region.toLowerCase());
      return matchesSearch && matchesDuration && matchesDifficulty && matchesRegion;
    });
    setFilteredPackages(filtered);
    setVisiblePackages(filtered.slice(0, Math.min(itemsPerLoad, filtered.length))); // Adjust for small arrays
    setCurrentCount(Math.min(itemsPerLoad, filtered.length));
  };

  useEffect(() => {
    handleFilterApply();
  }, [searchQuery, duration, difficulty, region]);

  // Load more packages
  const loadMore = () => {
    const newCount = currentCount + itemsPerLoad;
    setVisiblePackages(filteredPackages.slice(0, newCount));
    setCurrentCount(newCount);
  };

  // Handlers
  const handlePackageClick = (slug) => navigate(`/packages/${slug}`);
  const handleBookNow = (pkg) => setSelectedPackage(pkg);
  const handleCloseBooking = () => setSelectedPackage(null);
  const handleBooking = (date, formData) => {
    console.log('Booking:', { packageData: selectedPackage, date, formData });
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 3000);
  };
  const toggleWishlist = (pkgId) => {
    setWishlist((prev) =>
      prev.includes(pkgId) ? prev.filter((id) => id !== pkgId) : [...prev, pkgId]
    );
  };
  const openQuickView = (pkg) => setQuickView(pkg);
  const closeQuickView = () => setQuickView(null);

  // Currency conversion (mock rates)
  const convertPrice = (price) => {
    const rates = { USD: 1, EUR: 0.92, GBP: 0.78 };
    return Math.round(price * (rates[currency] || 1));
  };

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-100 to-blue-100 pt-20 pb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white shadow-md rounded-xl p-6 mb-8 animate-pulse">
            <div className="h-10 bg-gray-200 rounded w-1/3 mb-4"></div>
            <div className="flex gap-4">
              <div className="h-12 bg-gray-200 rounded w-2/3"></div>
              <div className="h-12 bg-gray-200 rounded w-1/4"></div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white rounded-2xl shadow-lg animate-pulse">
                <div className="h-60 bg-gray-200 rounded-t-2xl"></div>
                <div className="p-4 space-y-4">
                  <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                  </div>
                  <div className="h-10 bg-gray-200 rounded"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-100 to-blue-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 py-12 px-4 sm:px-6 lg:px-8 mt-20">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-center"
      >
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 tracking-tight">
          <span className="bg-gradient-to-r from-green-400 via-blue-500 to-indigo-600 bg-clip-text text-transparent">
            Trek Nepal’s Finest
          </span>
        </h1>
        <p className="text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto">
          Discover world-class trekking adventures curated for explorers like you. Book now and conquer the Himalayas!
        </p>
      </motion.div>

      <div className="max-w-7xl mx-auto">
        {/* Top Bar Filters */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-transparent rounded-lg sm:p-1 mb-1 top-20"
        >
          <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
            <div className="relative w-full sm:w-1/2">
              <input
                type="text"
                placeholder="Search treks in Nepal..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white shadow-sm text-gray-700 placeholder-gray-400 transition-all"
              />
              <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-600 to-green-600 text-white rounded-full hover:from-indigo-700 hover:to-green-700 transition-colors shadow-md"
            >
              <FaFilter className="w-4 h-4" /> Filters {showFilters ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>

          {showFilters && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.3 }}
              className="mt-4 grid grid-cols-1 sm:grid-cols-4 gap-4"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Duration</label>
                <select
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="">All</option>
                  <option value="3 Days">3 days</option>
                  <option value="4 Days">4 days</option>
                  <option value="6 Days">6 days</option>
                  <option value="10 Days">10 days</option>
                  <option value="11 Days">11 days</option>
                  <option value="12 Days">12 days</option>
                  <option value="16 Days">16 days</option>
                  <option value="Flexible">Flexible</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Difficulty</label>
                <select
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="">All</option>
                  <option value="Easy to Moderate">Easy</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Moderate to Challenging">Moderate to Challenging</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Region</label>
                <select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="">All Nepal</option>
                  <option value="Everest">Everest</option>
                  <option value="Annapurna">Annapurna</option>
                  <option value="Langtang">Langtang</option>
                  <option value="Manaslu">Manaslu</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Currency</label>
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 text-sm"
                >
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
            </motion.div>
          )}
        </motion.div>

        {/* Package Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {visiblePackages.map((pkg, index) => ( // Removed .slice(1)
            <motion.div
              key={pkg._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 hover:shadow-xl hover:scale-105 cursor-pointer"
              onClick={() => handlePackageClick(pkg.slug)}
            >
              <div className="relative h-56 sm:h-60 overflow-hidden">
                <img
                  src={pkg.overallImages[0]}
                  alt={pkg.title}
                  className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                <button
                  onClick={(e) => { e.stopPropagation(); toggleWishlist(pkg._id); }}
                  className="absolute top-2 right-2 p-1 bg-white/80 rounded-full text-red-500 hover:bg-white transition-colors"
                >
                  <FaHeart className={wishlist.includes(pkg._id) ? 'text-red-500' : 'text-gray-500'} />
                </button>
                <div className="absolute top-2 left-2 flex items-center bg-white/90 px-2 py-1 rounded-full text-sm font-semibold text-indigo-600 shadow-md">
                  <Star className="w-4 h-4 text-yellow-400 mr-1" /> 4.9
                </div>
                <div className="absolute bottom-4 left-4 text-white">
                  <p className="text-sm font-medium">From</p>
                  <p className="text-xl sm:text-2xl font-bold">{currency} {convertPrice(pkg.price)}</p>
                </div>
              </div>
              <div className="p-4 sm:p-5">
                <h2 className="text-lg sm:text-xl font-bold text-gray-800 mb-3 line-clamp-2">
                  {pkg.title}
                </h2>
                <div className="grid grid-cols-2 gap-3 text-gray-600 text-sm sm:text-base">
                  <div className="flex items-center"><FaGlobe className="w-4 h-4 text-indigo-600 mr-2" /> {pkg.country}</div>
                  <div className="flex items-center"><FaMapMarkerAlt className="w-4 h-4 text-indigo-600 mr-2" /> {pkg.region}</div>
                  <div className="flex items-center"><LiaCalendarDaySolid className="w-5 h-5 text-indigo-600 mr-2" /> {pkg.duration}</div>
                  <div className="flex items-center"><FaWalking className="w-4 h-4 text-indigo-600 mr-2" /> {pkg.dailyDistance}</div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <button
                    onClick={(e) => { e.stopPropagation(); openQuickView(pkg); }}
                    className="text-indigo-600 hover:underline text-sm"
                  >
                    Quick View
                  </button>
                  <button
                    onClick={(e) => { e.stopPropagation(); handleBookNow(pkg); }}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors shadow-md"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
          {visiblePackages.length === 0 && (
            <div className="col-span-full text-center text-gray-600 text-lg sm:text-xl py-12">
              No treks found matching your criteria. Try adjusting your filters!
            </div>
          )}
        </div>

        {/* Load More */}
        {visiblePackages.length < filteredPackages.length && (
          <div className="text-center mt-10 sm:mt-12">
            <button
              onClick={loadMore}
              className="px-6 py-3 text-sm bg-gradient-to-r from-indigo-600 to-green-600 text-white font-semibold rounded-lg shadow-md hover:from-indigo-700 hover:to-green-700 transition-all duration-300"
            >
              Load More Treks
            </button>
          </div>
        )}

        {/* Featured Trek */}
        {filteredPackages.length > 0 && (
          <div
            onClick={() => handlePackageClick(filteredPackages[0].slug)}
            className="mt-3 relative rounded-3xl shadow-2xl overflow-hidden bg-white transition-transform duration-300 hover:shadow-3xl hover:scale-105 cursor-pointer"
          >
            <div className="relative h-64 sm:h-80 md:h-96">
              <img
                src={filteredPackages[0].overallImages[0]}
                alt={filteredPackages[0].title}
                className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent"></div>
              <div className="absolute top-4 right-4 bg-yellow-400 text-white px-3 py-1 rounded-full flex items-center gap-2 shadow-md">
                <Star className="w-5 h-5" /> Featured Trek
              </div>
              <div className="absolute bottom-4 left-4 right-4 text-white">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 line-clamp-2 drop-shadow-lg">
                  {filteredPackages[0].title}
                </h2>
                <p className="text-lg font-semibold drop-shadow-md">{currency} {convertPrice(filteredPackages[0].price)}</p>
                <button
                  onClick={(e) => { e.stopPropagation(); handleBookNow(filteredPackages[0]); }}
                  className="mt-2 px-6 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors shadow-md"
                >
                  Book Before Spots Run Out!
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="bg-gradient-to-r from-indigo-600 to-green-600 mt-6 sm:mt-16 py-12 sm:py-16 rounded-3xl text-center text-white shadow-xl"
        >
          <div>
            <h2 className="text-xl sm:text-4xl md:text-5xl font-bold mb-2 tracking-tight">
              Ready to Trek Nepal?
            </h2>
            <p className="text-lg sm:text-xl md:text-2xl mb-2 max-w-3xl mx-auto">
              Join thousands of adventurers who’ve conquered the Himalayas with us. Book your trek today!
            </p>
            <button
              onClick={() => navigate('/packages')}
              className="px-2 py-2 text-sm bg-white text-indigo-600 font-semibold rounded-lg hover:bg-gray-100 transition-colors shadow-md hover:shadow-xl"
            >
              Start Your Journey
            </button>
          </div>
        </motion.div>

        {/* Travel Tips */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="mt-12 sm:mt-16 p-6 sm:p-8 bg-white rounded-3xl shadow-lg text-center border border-gray-100"
        >
          <h3 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 tracking-tight">
            New to Trekking in Nepal?
          </h3>
          <p className="text-gray-600 text-base sm:text-lg">
            Get expert tips on visas, gear, and more to prepare for your Himalayan adventure.
          </p>
          <button
            onClick={() => navigate('/blog')}
            className="mt-4 px-6 py-3 bg-indigo-600 text-sm text-white font-semibold rounded-lg hover:bg-indigo-700 transition-colors shadow-md"
          >
            Read Trekking Tips
          </button>
        </motion.div>

        <CampaignSection />
      </div>

      {/* Quick View Modal */}
      {quickView && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="bg-white rounded-2xl p-6 max-w-lg w-full mx-4 shadow-2xl"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-4">{quickView.title}</h2>
            <img
              src={quickView.overallImages[0]}
              alt={quickView.title}
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <p className="text-gray-600 mb-4">{quickView.description?.substring(0, 150) || "A thrilling trek awaits you!"}...</p>
            <div className="flex justify-between items-center">
              <p className="text-lg font-semibold text-indigo-600">{currency} {convertPrice(quickView.price)}</p>
              <button
                onClick={() => handleBookNow(quickView)}
                className="px-4 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors"
              >
                Book Now
              </button>
            </div>
            <button
              onClick={closeQuickView}
              className="mt-4 text-gray-500 hover:text-gray-700 text-sm underline"
            >
              Close
            </button>
          </motion.div>
        </div>
      )}

      {selectedPackage && (
        <BookingCalendar
          packageData={selectedPackage}
          onClose={handleCloseBooking}
          onBook={handleBooking}
        />
      )}

      <Confetti active={showConfetti} config={confettiConfig} />
      <Footer />
    </div>
  );
};

export default PackageList;