import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiSearch } from 'react-icons/fi'; // Feather Icons
import { FaMapMarkerAlt, FaHiking, FaStar, FaUser } from 'react-icons/fa'; // Font Awesome
import Footer from '../footer/Footer';
import debounce from 'lodash/debounce';

const BlogList = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [itemsPerLoad] = useState(6);
  const [currentCount, setCurrentCount] = useState(itemsPerLoad);

  // Fetch blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/all`);
        if (Array.isArray(response.data)) {
          setBlogs(response.data);
          setFilteredBlogs(response.data);
          setVisibleBlogs(response.data.slice(0, itemsPerLoad));
          setError(null);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Failed to fetch blogs:', error);
        setError(error.response?.data?.message || 'Failed to fetch blogs. Please try again later.');
        setBlogs([]);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((query) => {
      const lowerCaseQuery = query.toLowerCase();
      const filtered = blogs.filter(
        (blog) =>
          blog.title.toLowerCase().includes(lowerCaseQuery) ||
          (blog.content && blog.content.toLowerCase().includes(lowerCaseQuery))
      );
      setFilteredBlogs(filtered);
      setVisibleBlogs(filtered.slice(0, itemsPerLoad));
      setCurrentCount(itemsPerLoad);
    }, 300),
    [blogs]
  );

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
    handleSearch(e.target.value);
  };

  // Category filter
  const handleCategoryFilter = (category) => {
    setCategoryFilter(category);
    const filtered = category
      ? blogs.filter((blog) => blog.category?.toLowerCase() === category.toLowerCase())
      : blogs;
    setFilteredBlogs(filtered);
    setVisibleBlogs(filtered.slice(0, itemsPerLoad));
    setCurrentCount(itemsPerLoad);
  };

  // Load more blogs
  const loadMore = () => {
    const newCount = currentCount + itemsPerLoad;
    setVisibleBlogs(filteredBlogs.slice(0, newCount));
    setCurrentCount(newCount);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-blue-50">
        <div className="w-12 h-12 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-red-600 text-lg font-semibold bg-red-100 px-6 py-3 rounded-lg shadow">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 py-12 px-4 sm:px-6 lg:px-8 mt-20">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-4"
        >
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 mb-4">
            <span className="bg-gradient-to-r from-green-400 to-indigo-600 bg-clip-text text-transparent">
              Trekking Tales & Tips
            </span>
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Discover inspiring stories, expert advice, and insider tips to fuel your next Himalayan adventure.
          </p>
        </motion.div>

        {/* Search and Filters */}
        <div className="flex flex-col sm:flex-row gap-4 mb-8 max-w-4xl mx-auto">
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search blogs..."
              className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white shadow-sm text-gray-700 placeholder-gray-400 transition-all"
              value={searchQuery}
              onChange={onSearchChange}
            />
            <FiSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
          </div>
          <div className="flex gap-2">
            {['All', 'Destinations', 'Tips', 'Gear'].map((cat) => (
              <button
                key={cat}
                onClick={() => handleCategoryFilter(cat === 'All' ? '' : cat)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  categoryFilter === (cat === 'All' ? '' : cat)
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {cat}
              </button>
            ))}
          </div>
        </div>

        {/* Featured Blog */}
        {filteredBlogs.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mb-8"
          >
            <Link to={`/blog/${filteredBlogs[0].slug}`} className="block">
              <div className="relative rounded-xl shadow-lg overflow-hidden bg-white transition-transform duration-300 hover:shadow-xl hover:scale-105">
                <div className="relative h-64 sm:h-80 md:h-96">
                  <img
                    src={filteredBlogs[0].images[0] || filteredBlogs[0].image}
                    alt={filteredBlogs[0].title}
                    className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
                  <div className="absolute bottom-4 left-4 right-4">
                    <h2 className="text-2xl md:text-3xl font-bold text-white mb-2 line-clamp-2">
                      {filteredBlogs[0].title}
                    </h2>
                    <p className="text-white text-sm">Featured Story</p>
                  </div>
                </div>
              </div>
            </Link>
          </motion.div>
        )}

        {/* Blog Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {visibleBlogs.slice(1).map((blog, index) => (
            <motion.div
              key={blog.slug}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Link to={`/blog/${blog.slug}`} className="block h-full">
                <div className="relative h-full rounded-xl shadow-lg overflow-hidden bg-white transition-transform duration-300 hover:shadow-xl hover:scale-105">
                  <div className="relative h-48 overflow-hidden">
                    <img
                      src={blog.images[0] || blog.image}
                      alt={blog.title}
                      className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                    {index === 0 && (
                      <span className="absolute top-2 right-2 px-2 py-1 bg-yellow-400 text-white text-xs font-semibold rounded-full flex items-center">
                        <FaStar className="mr-1" /> Most Popular
                      </span>
                    )}
                  </div>
                  <div className="p-5">
                    <h2 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2">
                      {blog.title}
                    </h2>
                    {/* <p className="text-gray-600 text-sm line-clamp-3">
                      {blog.content.substring(0, 100)}...
                    </p> */}
                    <div className="mt-3 flex flex-wrap gap-2">
                      {blog.Slug?.slice(0, 3).map((word, index) => (
                        <span
                          key={index}
                          className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs"
                        >
                          #{word}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
          {visibleBlogs.length === 0 && (
            <div className="col-span-full text-center text-gray-600 text-lg">
              No blogs found matching your search.
            </div>
          )}
        </div>

        {/* Load More Button */}
        {visibleBlogs.length < filteredBlogs.length && (
          <div className="text-center mt-10">
            <button
              onClick={loadMore}
              className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-full shadow-md hover:bg-indigo-700 transition-all duration-300"
            >
              Load More Blogs
            </button>
          </div>
        )}

        {/* Trek Booking Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mt-12 p-6 bg-white rounded-xl shadow-lg border border-gray-200"
        >
          <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 flex items-center">
            <FaHiking className="mr-2 text-indigo-600" /> Ready to Trek?
          </h3>
          <p className="text-gray-600 mb-6">
            Book your next adventure with us and explore the Himalayas like never before!
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="p-4 bg-green-50 rounded-lg hover:shadow-md transition-all duration-300">
              <h4 className="text-md font-medium text-gray-800">Everest Base Camp Trek</h4>
              <p className="text-sm text-gray-600">14 days of iconic Himalayan adventure.</p>
              <button
                onClick={() => navigate('/packages/everest-base-camp-trek')}
                className="mt-2 inline-block px-4 py-2 bg-indigo-600 text-white text-sm rounded-full hover:bg-indigo-700 transition-colors"
              >
                Book Now
              </button>
            </div>
            <div className="p-4 bg-green-50 rounded-lg hover:shadow-md transition-all duration-300">
              <h4 className="text-md font-medium text-gray-800">Annapurna Circuit</h4>
              <p className="text-sm text-gray-600">A classic trek with stunning views.</p>
              <button
                onClick={() => navigate('/packages/annapurna-circuit')}
                className="mt-2 inline-block px-4 py-2 bg-indigo-600 text-white text-sm rounded-full hover:bg-indigo-700 transition-colors"
              >
                Book Now
              </button>
            </div>
          </div>
          <div className="text-center mt-6">
            <Link
              to="/packages"
              className="inline-block px-6 py-2 bg-green-600 text-white font-semibold rounded-full hover:bg-green-700 transition-colors"
            >
              View All Packages
            </Link>
          </div>
        </motion.div>

        {/* Quick Links */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mt-12 grid grid-cols-1 sm:grid-cols-3 gap-4"
        >
          <Link to="/packages" className="p-4 bg-indigo-50 rounded-lg text-center hover:bg-indigo-100 transition-colors">
            <FaMapMarkerAlt className="mx-auto text-indigo-600 text-2xl mb-2" />
            <p className="text-gray-800 font-medium">Explore Packages</p>
          </Link>
          <Link to="/contact" className="p-4 bg-indigo-50 rounded-lg text-center hover:bg-indigo-100 transition-colors">
            <FaUser className="mx-auto text-indigo-600 text-2xl mb-2" />
            <p className="text-gray-800 font-medium">Contact Us</p>
          </Link>
          <Link to="/about" className="p-4 bg-indigo-50 rounded-lg text-center hover:bg-indigo-100 transition-colors">
            <FaHiking className="mx-auto text-indigo-600 text-2xl mb-2" />
            <p className="text-gray-800 font-medium">About TrekNepal</p>
          </Link>
        </motion.div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogList;