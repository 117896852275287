import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import './UpdateBlog.css';
import { useParams, useNavigate } from 'react-router-dom';

const UpdateBlog = () => {
  const { id } = useParams(); // Retrieve the blog ID from the URL
  const navigate = useNavigate(); // For navigation after updating the blog

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch the existing blog data when the component mounts
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogs/${id}`);
       const {title, content} = response.data;
        setTitle(title);
        setContent(content);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlogData();
  }, [id]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Store the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create a FormData object to send the file and text fields
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      if (image) {
        formData.append('image', image); // Append the selected file if it exists
      }

      await axios.put(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,

        },
      });

      alert('Blog updated successfully!');
      navigate('/admin/bloglist'); // Redirect to the blog list page after updating
    } catch (error) {
      console.error('Error updating blog:', error);
      alert('Failed to update blog. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="blog-post-container mt-28">
      <h2 className="blog-post-title">Update Blog Post</h2>
      <form onSubmit={handleSubmit} className="blog-post-form">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            type="text"
            value={title}
            placeholder= {title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Content</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
            className="form-textarea"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="form-input"
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Updating Blog...' : 'Update Blog'}
        </button>
      </form>
    </div>
  );
};

export default UpdateBlog;
