import React, { useState, useMemo, useCallback } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const API_ENDPOINT = "https://nodejs-serverless-function-express-henna-theta.vercel.app/api/create";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Preprocess plain text into structured HTML
  const preprocessContent = (plainText) => {
    const lines = plainText.split("\n").filter((line) => line.trim());
    let structuredContent = "";
    let inList = false;
    let currentList = [];
    let nextLineIsFactContent = false;

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();

      // Main title (first line before any numbering) as H1
      if (index === 0 && !trimmedLine.match(/^\d+\.\s/)) {
        structuredContent += `<h1>${trimmedLine}</h1>\n`;
        return;
      }

      // H2 for major sections (e.g., "Final Thoughts")
      if (
        trimmedLine.match(/^Final Thoughts$/) ||
        trimmedLine.match(/^What You Can Do to Help/)
      ) {
        if (inList && currentList.length > 0) {
          structuredContent += "<ul>\n" + currentList.join("") + "</ul>\n";
          currentList = [];
          inList = false;
        }
        const headingText = trimmedLine.replace(/:$/, "");
        structuredContent += `<h2 id="${headingText.toLowerCase().replace(/\s+/g, "-")}">${headingText}</h2>\n`;
      }
      // H3 for animal entries (numbered items)
      else if (trimmedLine.match(/^\d+\.\s[A-Za-z\s]+\(/)) {
        if (inList && currentList.length > 0) {
          structuredContent += "<ul>\n" + currentList.join("") + "</ul>\n";
          currentList = [];
          inList = false;
        }
        const animalName = trimmedLine.replace(/^\d+\.\s/, "");
        structuredContent += `<h3>${animalName}</h3>\n`;
      }
      // Bold "Interesting Fact" as single paragraph with next line
      else if (trimmedLine.startsWith("Interesting Fact:")) {
        if (inList && currentList.length > 0) {
          structuredContent += "<ul>\n" + currentList.join("") + "</ul>\n";
          currentList = [];
          inList = false;
        }
        nextLineIsFactContent = true; // Flag to combine with next line
      }
      // List items (e.g., starting with "✅" or "- ")
      else if (trimmedLine.match(/^[-✅]\s/)) {
        if (!inList) {
          inList = true;
        }
        const listItem = trimmedLine.replace(/^[-✅]\s/, "").trim();
        currentList.push(`<li>${listItem}</li>\n`);
      }
      // Default to paragraph or combine with "Interesting Fact"
      else if (trimmedLine.length > 0) {
        if (inList && currentList.length > 0) {
          structuredContent += "<ul>\n" + currentList.join("") + "</ul>\n";
          currentList = [];
          inList = false;
        }
        if (nextLineIsFactContent) {
          structuredContent += `<p><strong>Interesting Fact:</strong> ${trimmedLine}</p>\n`;
          nextLineIsFactContent = false;
        } else {
          structuredContent += `<p>${trimmedLine}</p>\n`;
        }
      }
    });

    if (inList && currentList.length > 0) {
      structuredContent += "<ul>\n" + currentList.join("") + "</ul>\n";
    }

    return structuredContent;
  };

  // Custom image upload handler for ReactQuill
  const handleImageUpload = useCallback(() => {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        if (!file) return;

        try {
          const token = localStorage.getItem("adminToken");
          if (!token) throw new Error("Please login to upload images.");

          const formData = new FormData();
          formData.append("image", file);
          formData.append("title", "temp");
          formData.append("content", "temp");

          const response = await axios.post(API_ENDPOINT, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.blogPost?.images?.length > 0) {
            resolve(response.data.blogPost.images[0]);
          } else {
            reject(new Error("Image upload failed."));
          }
        } catch (error) {
          console.error("Image upload error:", error);
          reject(error);
        }
      };
    });
  }, []);

  // ReactQuill modules configuration
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["link", "image", "video"],
          ["blockquote", "code-block"],
          ["clean"],
        ],
        handlers: {
          image: async function () {
            const editor = this.quill;
            try {
              const imageUrl = await handleImageUpload();
              if (imageUrl) {
                const range = editor.getSelection(true);
                editor.insertEmbed(range.index, "image", imageUrl);
              }
            } catch (error) {
              alert("Failed to upload image: " + error.message);
            }
          },
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [handleImageUpload]
  );

  // Handle content change with preprocessing for paste events
  const handleContentChange = (value, delta, source, editor) => {
    if (source === "user") {
      const isPaste = delta.ops.some((op) => op.insert && typeof op.insert === "string" && op.insert.includes("\n"));
      if (isPaste) {
        const plainText = editor.getText();
        const structuredContent = preprocessContent(plainText);
        setContent(structuredContent);
      } else {
        setContent(value);
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!title.trim() || !content.trim()) {
      setError("Title and content are required.");
      setLoading(false);
      return;
    }

    if (!image) {
      setError("A featured image is required.");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("adminToken");
      if (!token) {
        setError("Please login to create a blog.");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      formData.append("image", image);

      const response = await axios.post(API_ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      alert(response.data.message || "Blog created successfully!");
      setTitle("");
      setContent("");
      setImage(null);
    } catch (error) {
      console.error("Error creating blog:", error);
      setError(
        error.response?.data?.message || "Failed to create blog. Try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full bg-white shadow-lg rounded-xl p-8">
        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
          Create Blog Post
        </h2>
        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Title */}
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Blog Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter your blog title"
            />
          </div>

          {/* Content */}
          <div>
            <label
              htmlFor="content"
              className="block text-sm font-medium text-gray-700"
            >
              Content
            </label>
            <div className="mt-1">
              <ReactQuill
                theme="snow"
                value={content}
                onChange={handleContentChange}
                modules={modules}
                className="bg-white min-h-[300px] rounded-md border border-gray-300"
                placeholder="Paste or write your blog content here..."
              />
            </div>
          </div>

          {/* Featured Image */}
          <div>
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-700"
            >
              Featured Image
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
            />
            <p className="mt-1 text-sm text-gray-500">
              Upload a featured image for your blog.
            </p>
            {image && (
              <div className="mt-2">
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  className="w-20 h-20 object-cover rounded-md"
                />
              </div>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Creating Blog..." : "Create Blog"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateBlog;