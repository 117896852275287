import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PackageUpload = () => {
  const { slug } = useParams(); // For update mode
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    country: '',
    region: '',
    duration: '',
    difficulty: '',
    dailyDistance: '',
    dailyAscent: '',
    price: '',
    included: '',
    excluded: '',
    itinerary: [],
  });
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  // Fetch existing package data if in update mode
  useEffect(() => {
    if (slug) {
      setIsUpdateMode(true);
      const fetchPackage = async () => {
        try {
          const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`);
          const data = response.data;
          setFormData({
            ...data,
            included: data.included.join(', '),
            excluded: data.excluded.join(', '),
          });
          // Note: Files won't be pre-populated as they're not stored in the form state
        } catch (error) {
          console.error('Error fetching package:', error);
        }
      };
      fetchPackage();
    }
  }, [slug]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleItineraryChange = (index, field, value) => {
    const updatedItinerary = [...formData.itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      [field]: value,
    };
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  const addItineraryDay = () => {
    setFormData((prevData) => ({
      ...prevData,
      itinerary: [
        ...prevData.itinerary,
        {
          day: prevData.itinerary.length + 1,
          title: '',
          description: '',
          duration: '',
          distance: '',
          ascent: '',
          descent: '',
        },
      ],
    }));
  };

  const removeItineraryDay = (index) => {
    const updatedItinerary = formData.itinerary.filter((_, i) => i !== index);
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        alert('No authentication token found. Please login again.');
        return;
      }

      const totalSize = files.reduce((sum, file) => sum + file.size, 0);
      if (totalSize > 50 * 1024 * 1024) {
        alert('Total file size exceeds 50MB. Please reduce image sizes.');
        return;
      }

      const submitFormData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === 'itinerary') {
          submitFormData.append('itinerary', JSON.stringify(formData.itinerary));
        } else if (key === 'included' || key === 'excluded') {
          const values = formData[key].split(',').map((item) => item.trim());
          submitFormData.append(key, JSON.stringify(values));
        } else {
          submitFormData.append(key, formData[key]);
        }
      });

      files.forEach((file) => submitFormData.append('images', file));

      const url = isUpdateMode
        ? `https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`
        : 'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package';
        // : 'http://localhost:5000/api/package';

      const method = isUpdateMode ? 'put' : 'post';

      const response = await axios({
        method,
        url,
        data: submitFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        timeout: 300000,
        maxContentLength: 50 * 1024 * 1024,
        maxBodyLength: 50 * 1024 * 1024,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('Upload progress:', percentCompleted, '%');
        },
      });

      alert(isUpdateMode ? 'Package updated successfully!' : 'Package uploaded successfully!');
      if (!isUpdateMode) {
        setFormData({
          title: '',
          description: '',
          country: '',
          region: '',
          duration: '',
          difficulty: '',
          dailyDistance: '',
          dailyAscent: '',
          price: '',
          included: '',
          excluded: '',
          itinerary: [],
        });
        setFiles([]);
      }
    } catch (error) {
      console.error('Error details:', error);
      alert(error.response?.data?.message || `Failed to ${isUpdateMode ? 'update' : 'upload'} package.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <h2 className="text-3xl font-bold text-gray-900 text-center">
          {isUpdateMode ? 'Update Trek Package' : 'Upload New Trek Package'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Country</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Region</label>
              <input
                type="text"
                name="region"
                value={formData.region}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Duration</label>
              <input
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Difficulty</label>
              <select
                name="difficulty"
                value={formData.difficulty}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              >
                <option value="">Select Difficulty</option>
                <option value="Easy">Easy</option>
                <option value="Moderate">Moderate</option>
                <option value="Hard">Hard</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Daily Distance</label>
              <input
                type="text"
                name="dailyDistance"
                value={formData.dailyDistance}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Daily Ascent</label>
              <input
                type="text"
                name="dailyAscent"
                value={formData.dailyAscent}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows="4"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">What’s Included (comma separated)</label>
            <textarea
              name="included"
              value={formData.included}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows="3"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">What’s Excluded (comma separated)</label>
            <textarea
              name="excluded"
              value={formData.excluded}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows="3"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Upload Images (Max 20)</label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
            />
            <p className="mt-1 text-sm text-gray-500">Selected files: {files.length}</p>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Itinerary</h3>
            {formData.itinerary.map((day, index) => (
              <div key={index} className="p-4 bg-gray-50 rounded-md space-y-4">
                <h4 className="text-md font-semibold">Day {index + 1}</h4>
                <input
                  type="text"
                  placeholder="Title"
                  value={day.title}
                  onChange={(e) => handleItineraryChange(index, 'title', e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
                <textarea
                  placeholder="Description"
                  value={day.description}
                  onChange={(e) => handleItineraryChange(index, 'description', e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="Duration"
                    value={day.duration}
                    onChange={(e) => handleItineraryChange(index, 'duration', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Distance"
                    value={day.distance}
                    onChange={(e) => handleItineraryChange(index, 'distance', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Ascent"
                    value={day.ascent}
                    onChange={(e) => handleItineraryChange(index, 'ascent', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Descent"
                    value={day.descent}
                    onChange={(e) => handleItineraryChange(index, 'descent', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={() => removeItineraryDay(index)}
                  className="text-red-600 hover:text-red-800 text-sm"
                >
                  Remove Day
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addItineraryDay}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              Add Itinerary Day
            </button>
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              loading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {loading ? 'Processing...' : isUpdateMode ? 'Update Package' : 'Upload Package'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PackageUpload;