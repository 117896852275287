import React from "react";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
  return (
    <div className="admin-layout">
      <h1 className="text-4xl">Admin Panel</h1>
      <Outlet /> {/* Renders nested routes like /admin */}
    </div>
  );
};

export default AdminLayout;
