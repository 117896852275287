import React, { useEffect, useState } from 'react';

const AdminApproval = () => {
  const [pendingBlogs, setPendingBlogs] = useState([]);
  const [pendingPackages, setPendingPackages] = useState([]);
  const [pendingReviews, setPendingReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const BASE_URL = 'http://localhost:5000';
  const BASE_URL = 'https://nodejs-serverless-function-express-henna-theta.vercel.app';

  const fetchPendingApprovals = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('adminToken');

      const blogResponse = await fetch(`${BASE_URL}/api/pending`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!blogResponse.ok) throw new Error('Failed to fetch pending blogs');
      const blogs = await blogResponse.json();

      const packageResponse = await fetch(`${BASE_URL}/api/package/pending`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!packageResponse.ok) throw new Error('Failed to fetch pending packages');
      const packages = await packageResponse.json();

      const reviewResponse = await fetch(`${BASE_URL}/api/reviews/pending`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!reviewResponse.ok) throw new Error('Failed to fetch pending reviews');
      const reviews = await reviewResponse.json();

      setPendingBlogs(blogs);
      setPendingPackages(packages);
      setPendingReviews(reviews);
    } catch (err) {
      console.error('Error fetching pending approvals:', err);
      setError('Failed to load pending approvals');
    } finally {
      setLoading(false);
    }
  };

  const handleApproval = async (type, id, approve) => {
    try {
      const token = localStorage.getItem('adminToken');
      const endpoint = approve ? 'approve' : 'reject';
      const response = await fetch(
        `${BASE_URL}/api/admin/${endpoint}/${type}/${id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ approve }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update ${type} status: ${errorText}`);
      }

      // Update state based on type
      if (type === 'blog') {
        setPendingBlogs((prev) => prev.filter((blog) => blog._id !== id));
      } else if (type === 'package') {
        setPendingPackages((prev) => prev.filter((pkg) => pkg._id !== id));
      } else if (type === 'review') {
        setPendingReviews((prev) => prev.filter((review) => review._id !== id));
      }
    } catch (err) {
      console.error(`Error updating ${type} status:`, err);
      setError(`Failed to update ${type} status`);
    }
  };

  useEffect(() => {
    fetchPendingApprovals();
  }, []);

  return (
    <div className="container mx-auto p-6 space-y-8">
      <h2 className="text-2xl font-bold text-gray-800">Pending Approvals</h2>

      {error && (
        <p className="text-red-600 bg-red-100 p-3 rounded-md">{error}</p>
      )}

      {loading ? (
        <p className="text-gray-600">Loading pending approvals...</p>
      ) : (
        <div className="space-y-10">
          <section>
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Pending Blogs</h3>
            {pendingBlogs.length > 0 ? (
              pendingBlogs.map((blog) => (
                <div key={blog._id} className="bg-white p-5 rounded-lg shadow-lg mb-4 border border-gray-200">
                  <h4 className="text-lg font-medium text-gray-800">{blog.title}</h4>
                  <p className="text-gray-600 mt-2">{blog.content}</p>
                  <div className="mt-4 flex space-x-4">
                    <button
                      onClick={() => handleApproval('blog', blog._id, true)}
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-200"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleApproval('blog', blog._id, false)}
                      className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No pending blogs at the moment.</p>
            )}
          </section>

          <section>
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Pending Packages</h3>
            {pendingPackages.length > 0 ? (
              pendingPackages.map((pkg) => (
                <div key={pkg._id} className="bg-white p-5 rounded-lg shadow-lg mb-4 border border-gray-200">
                  <h4 className="text-lg font-medium text-gray-800">{pkg.name}</h4>
                  <p className="text-gray-600 mt-2">{pkg.description}</p>
                  <div className="mt-4 flex space-x-4">
                    <button
                      onClick={() => handleApproval('package', pkg._id, true)}
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-200"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleApproval('package', pkg._id, false)}
                      className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No pending packages at the moment.</p>
            )}
          </section>

          <section>
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Pending Reviews</h3>
            {pendingReviews.length > 0 ? (
              pendingReviews.map((review) => (
                <div key={review._id} className="bg-white p-5 rounded-lg shadow-lg mb-4 border border-gray-200">
                  <h4 className="text-lg font-medium text-gray-800">{review.userName}</h4>
                  <p className="text-gray-600 mt-2">{review.content}</p>
                  <p className="text-gray-500 mt-1">Rating: {review.rating}/5</p>
                  <p className="text-gray-400 text-sm mt-1">
                    Source: {review.source} | Created: {new Date(review.createdAt).toLocaleDateString()}
                  </p>
                  <div className="mt-4 flex space-x-4">
                    <button
                      onClick={() => handleApproval('review', review._id, true)}
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-200"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleApproval('review', review._id, false)}
                      className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No pending reviews at the moment.</p>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

export default AdminApproval;