import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaClock, FaMountain, FaMapMarkerAlt, FaDollarSign, FaArrowRight, FaArrowLeft, FaStar } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { OrbitProgress } from 'react-loading-indicators';
import LazyImage from './LazyImage';

const Swiper = () => {
  const [packages, setPackages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all',
          { timeout: 15000 }
        );
        if (Array.isArray(response.data)) {
          setPackages(response.data);
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, []);

  useEffect(() => {
    if (packages.length === 0) return;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % packages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [packages.length]);

  const handlePackageClick = (slug) => navigate(`/packages/${slug}`);
  const handlePrev = () => setCurrentIndex((prevIndex) => (prevIndex - 1 + packages.length) % packages.length);
  const handleNext = () => setCurrentIndex((prevIndex) => (prevIndex + 1) % packages.length);

  return (
    <section
      className="relative py-4 overflow-hidden"
      itemScope
      itemType="https://schema.org/OfferCatalog"
    >
      {/* Background Video */}
      {/* <div className="absolute inset-0 z-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover opacity-80"
        >
          <source
            src="https://res.cloudinary.com/dewduogpc/video/upload/v1739599721/vidEO_y1jksp.mov"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> */}
        {/* Overlay for better text readability */}
        {/* <div className="absolute inset-0 bg-black/40"></div>
      </div> */}

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 max-w-7xl">
        <header className="text-center mb-2">
          <h3 className="text-indigo-200 font-semibold text-lg mb-2 tracking-wide">
            - Transformative Journeys Await -
          </h3>
          <h1
            className="text-4xl md:text-5xl font-bold text-white mb-4 leading-tight drop-shadow-lg"
            itemProp="name"
          >
            Discover Your True Self in Nepal's Majestic Himalayas
          </h1>
          <p
            className="text-gray-200 max-w-2xl mx-auto text-lg drop-shadow-md"
            itemProp="description"
          >
            Embark on an unforgettable trekking adventure with expert guides and breathtaking views.
          </p>
          <div className="w-24 h-1 bg-indigo-400 mx-auto rounded-full mt-4 shadow-md"></div>
        </header>

        {isLoading ? (
          <div className="flex justify-center items-center h-[400px]">
            <OrbitProgress color="#4f46e5" size="large" text="" />
          </div>
        ) : packages.length > 0 ? (
          <div className="relative">
            <AnimatePresence mode="wait">
              <motion.article
                key={currentIndex}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className="bg-white/95 rounded-2xl shadow-2xl overflow-hidden border border-gray-100 backdrop-blur-sm"
                itemProp="offers"
                itemScope
                itemType="https://schema.org/Offer"
              >
                <div className="grid md:grid-cols-2 gap-0">
                  {/* Image Section */}
                  <div className="relative h-[300px] md:h-[500px]">
                    <Suspense fallback={<div className="bg-gray-200 w-full h-full animate-pulse" />}>
                      <LazyImage
                        src={packages[currentIndex].overallImages[0]}
                        alt={`${packages[currentIndex].title} - Nepal Trekking Adventure`}
                        className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
                      />
                    </Suspense>
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                    <div className="absolute top-4 right-4 flex gap-2">
                      <span className="px-4 py-2 bg-white/90 text-indigo-600 rounded-full text-sm font-semibold shadow-lg">
                        {packages[currentIndex].difficulty}
                      </span>
                      <span className="px-4 py-2 bg-yellow-400/90 text-white rounded-full text-sm font-semibold shadow-lg flex items-center gap-1">
                        <FaStar /> 4.9
                      </span>
                    </div>
                  </div>

                  {/* Content Section */}
                  <div className="p-8 flex flex-col justify-between bg-white/95">
                    <div>
                      <h2
                        className="text-3xl font-bold text-gray-900 mb-4 hover:text-indigo-600 transition-colors"
                        itemProp="name"
                      >
                        {packages[currentIndex].title}
                      </h2>
                      <p
                        className="text-gray-600 mb-6 line-clamp-3"
                        itemProp="description"
                      >
                        {packages[currentIndex].description}
                      </p>
                      <div className="grid grid-cols-2 gap-4 mb-8">
                        <div className="flex items-center space-x-2 text-gray-600">
                          <FaClock className="text-indigo-600 text-xl" />
                          <span>{packages[currentIndex].duration} Days</span>
                        </div>
                        <div className="flex items-center space-x-2 text-gray-600">
                          <FaMapMarkerAlt className="text-indigo-600 text-xl" />
                          <span>{packages[currentIndex].region}</span>
                        </div>
                        <div className="flex items-center space-x-2 text-gray-600">
                          <FaMountain className="text-indigo-600 text-xl" />
                          <span>{packages[currentIndex].difficulty}</span>
                        </div>
                        <div className="flex items-center space-x-2 text-gray-600">
                          <FaDollarSign className="text-indigo-600 text-xl" />
                          <span itemProp="priceSpecification">From ${packages[currentIndex].price}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-auto flex gap-4">
                      <button
                        onClick={() => handlePackageClick(packages[currentIndex].slug)}
                        className="flex-1 bg-indigo-600 text-white py-3 px-6 rounded-xl hover:bg-indigo-700 transition-all duration-300 flex items-center justify-center space-x-2 group shadow-lg"
                      >
                        <span>Book Now</span>
                        <FaArrowRight className="group-hover:translate-x-1 transition-transform" />
                      </button>
                      <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-all duration-300">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </motion.article>
            </AnimatePresence>

            {/* Navigation Buttons */}
            <button
              onClick={handlePrev}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white p-3 rounded-full shadow-lg backdrop-blur-sm transition-all duration-300 z-20 hover:scale-110"
            >
              <FaArrowLeft className="text-gray-800 text-xl" />
            </button>
            <button
              onClick={handleNext}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white p-3 rounded-full shadow-lg backdrop-blur-sm transition-all duration-300 z-20 hover:scale-110"
            >
              <FaArrowRight className="text-gray-800 text-xl" />
            </button>

            {/* Dots Navigation */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2 z-20">
              {packages.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    index === currentIndex ? 'bg-indigo-400 scale-125' : 'bg-white/70'
                  }`}
                />
              ))}
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-200 relative z-10">No packages available at the moment.</p>
        )}
      </div>
    </section>
  );
};

export default Swiper;